$c-nav-color:                           $app-color-white;
$c-nav-color-highlight:                 $app-color-white;
$c-nav-color-bg:                        $app-color-primary;
$c-nav-color-bg-highlight:              $app-color-primary-light;

$c-nav-font-size-base:                  $app-font-size-base;
$c-nav-font-size-secondary:             $app-font-size-sm;

$c-nav-link-color:                      $app-color-white;

// TODO: refactor styles
.c-nav {
    position: relative;
    // top: 120px;
    width: 100%;
    height: calc(100% - 120px);
    overflow: visible;
    padding: 0;
    list-style: none;
    background-color: $c-nav-color-bg;
    @include mq($from: md) {
        padding-right: 2rem;
        padding-left: 2rem;
    }
    @include mq($from: navbar) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0;
        margin-bottom: 0;
        align-items: stretch;
        justify-content: space-between;
    }
}

.c-nav--level-1 {
    position: relative;
    // height: 0;
    overflow-y: visible;
}

.c-nav__item {
    position: relative;
    overflow: visible;
    width: 100%;
    margin: 0 0 $app-spacer-sm;
    padding: 0 0 0 $app-spacer-sm;
    background-color: $c-nav-color-bg;
    @include mq($from: navbar) {
        display: flex;
        align-items: center;
        width: auto;
        flex: 1 0 0;
        padding: 1rem 0;
        border-left: 1px solid $c-nav-color;
        margin: 0;
        &:last-child {
            border-right: 1px solid $c-nav-color;
        }
    }
    &--back-link,
    &--parent {
        border-bottom: 2px solid rgba(255,255,255, 0.1);
        display: flex;
        padding: 0;
    }
    @include when-inside('.c-nav__dropdown--open') {
        padding-left: 1rem;
        &:last-child {
            border-bottom: 0px solid $gray-600;
        }
    }
}

.c-nav__item--has-children {
    position: relative;
}

.c-nav__link {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: .75rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    text-decoration: none;
    color: $c-nav-color;
    font-family: $app-font-family-strong;
    text-transform: none;
    line-height: .7rem;
    transition: background-color 0.5s ease;
    font-size: $app-font-size-md;
    @include when-inside('.c-nav-bar__toggle') {
        line-height: 1rem;
    }
    @include mq($from: navbar) {
        padding-left: 0;
        text-align: center;
    }
    @include when-inside('.c-nav__item--current >') {
        color: $c-nav-color-highlight;
    }
    &:hover {
        text-decoration: none;
        background: transparent;
        color: $c-nav-color-highlight;
        background-color: $c-nav-color-bg-highlight;
    }
    &--active,
    &--open {
        text-decoration: none;
        background: transparent;
    }
    @include when-inside('.app-nav--level-1') {
        &:after {
            display: none;
        }
    }
    @include mq($from: navbar) {
        &:after {
            display: none;
        }
    }
    @include when-inside('.c-nav__dropdown') {
        padding-top: .5rem;
        padding-bottom: .5rem;
        font-size: $app-font-size-mds;
    }
}

.c-nav__link-text {
    flex: 1 0 calc(100% - 2rem);
    max-width: calc(100% - 2rem);
    line-height: $app-line-height-lg;
    @include mq($from: navbar) {
        flex: 1 0 100%;
        max-width: 100%;
    }
}

.c-nav__link--action {
    flex: 0 0 3rem;
    max-width: 3rem;
    padding-left: .75rem;
    align-self: flex-end;
    @include when-inside('.c-nav__item--parent') {
        width: 2rem;
        // padding-right: 1rem;
        padding-left: .25rem;
    }
    @include mq($from: navbar) {
        display: none;
    }
}

.c-nav__link--back-link {
    height: 3rem;
    // margin-top: .5rem;
}

.c-nav__link--has-icon {
    @include mq($until: navbar) {
        display: flex;
    }
}

.c-nav__toggle {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    width: 1rem;
    height: 1rem;
    line-height: 1;
    border-radius: 50%;
    &--close {
        border: 1px solid $app-color-primary;
    }
    @include mq($from: navbar) {
        display: none;
    }
    @include when-inside('.app-nav__link--back-link') {
        display: inline-block;
    }
    @include when-inside('.c-nav-bar__toggle') {
        width: 2rem;
        height: 2rem;
    }
    @include when-inside('.c-nav__item--has-dropdown') {
        transform: rotate(90deg);
    }
}

// Dropdown menus
.c-nav__dropdown {
    display: none;
    z-index: 1002;
    width: 100%;
    // height: calc(100vh - 60px);
    margin: 0;
    padding: 0;
    background-color: $c-nav-color-bg;
    &--open {
        display: block;
        height: auto;
        margin-bottom: 1rem;
        @include when-inside('.c-quick-links') {
        margin-bottom: 0;
        }
    }
}

.c-nav__dropdown--hidden {
    // right: -100%;
    // transition:right .25s linear;
}
