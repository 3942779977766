.c-button {
    display: inline-block;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    white-space: normal;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-family: $app-font-family-strong;
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
    @include transition($btn-transition);
    color: currentColor;

    &:hover {
        color: $body-color;
        text-decoration: if($link-hover-decoration == underline, none, null);
    }

    .btn-check:focus + &,
    &:focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &--disabled,
    &:disabled {
        opacity: .5;
        @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-image: none;
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }

    @include media-breakpoint-up(md) {
        white-space: nowrap;
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.c-button.disabled,
a.c-button--disabled,
fieldset[disabled] a.c-button {
    pointer-events: none;
}

a.c-button--active-action {
    pointer-events: none;
    border-width: 0 0 1px 0;
}

.c-button--default {
    @include button-outline-variant($app-color-gray, $white);
    @include when-inside('.searchPage') {
        @include button-outline-variant($white, $app-color-primary);
        &:hover {
            color: #fff;
            background: $app-color-primary-light;
        }
    }
}

.c-button--danger {
    @include button-outline-variant($app-color-danger, $white);
}

.c-button--primary {
    @include button-variant($app-color-primary, $app-color-primary);
}

.c-button--secondary {
    @include button-variant($app-color-secondary, $app-color-white);
}

.c-button--link {
    @include button-variant($app-color-gray, $app-color-gray);
}

.c-button + .c-button {
    margin-left: calc(#{$app-font-size-base} * .5);
}

.c-button--sm {
    @include button-size($btn-padding-y, $btn-padding-x, $app-font-size-sm, $btn-border-radius);
}

.c-button--lg {
    @include button-size($btn-padding-y, $btn-padding-x, $app-font-size-lg, $btn-border-radius);
}

.c-button--cancel {
    // background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#ccc' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.1'/></svg>") no-repeat;
    // background-size: 16px 16px;
    // background-position: 0 100%;
}

.c-button--reorder {
    cursor: move;
    // Disable reorder buttons when sortable javascript is not initialized
    // pointer-events: none;
}

.c-button--register {
    color: $app-color-white;
}
