$c-list-spacer:                             $app-spacer-lg;
$c-list-margin-x:                           $c-list-spacer;
$c-list-border-color:                       $app-color-text;
$c-list-border-width:                       1px;
$c-list-separator-color:                    $app-color-secondary;
$c-list-separator-margin:                   calc(#{$app-spacer-base} * 1.5);
$c-list-separator-width:                    90px;
$c-list-separator-height:                   .25rem;

.c-list {
    display: block;
    @include list-reset();
    &--grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &--baseline {
        align-content: baseline;
    }
    &--gutter {
        width: 100%;
        margin: auto;
        //margin-right: -($c-list-margin-x);
        //margin-left: -($c-list-margin-x);
    }
    &--tiles {
        margin-bottom: $app-spacer-base;
    }
}

.c-list__item {
    @include when-inside('.c-list--grid') {
        flex: 1 0 auto;
        max-width: 100%;
        @include mq($from: lg) {
            flex: 1 0 50%;
            max-width: 50%;
        }
    }

    margin-bottom: calc(#{$c-list-spacer} * 1.5);
    @include when-inside('.c-list--gutter') {
        @include mq($from: lg) {
            padding-right: $c-list-margin-x;
            &:not(:first-of-type) {
                padding-left: $c-list-margin-x;
            }
        }
    }

    @include when-inside('c-list--bordered') {
        border-bottom: 1px solid $c-list-border-color;
        &:last-of-type {
            border-bottom: 0px;
        }
    }
}

.c-list__item--card {
    position: relative;
    // &:not(:last-child) {
    //     &::after {
    //         @include mq($until: lg) {
    //             content: "";
    //             position: absolute;
    //             bottom: -(calc(#{$c-list-separator-margin} / 2));
    //             left: 0;
    //             width: $c-list-separator-width;
    //             height: $c-list-separator-height;
    //             background-color: $c-list-separator-color;
    //         }
    //     }
    // }
}

.c-list__item--action {
    color: lighten($app-color-text, 20%);
    &:hover {
        text-decoration: none;
        background-color: $app-color-gray-light;
        color: $app-color-text;
    }
}


.c-card-list {

}

.c-card-list__item {
    @include when-inside('.c-card-list') {
        padding-right: $app-spacer-base;
        &:not(:first-of-type) {
            padding-left: $app-spacer-base;
        }
        &:not(last) {
            @include mq($from: md) {
                border-right: 1px solid $app-color-gray;
            }
        }
        &:last-of-type {
            border-right: 0;
        }
    }
}
