.o-read-more {
	color: $app-color-white;
}

.o-read-more__text {
	color: $app-color-white;
}

.o-read-more__icon {
	margin-right: .5rem;
}
