$o-icon-width-default:              1rem;
$o-icon-height-default:             1rem;
$o-icon-color-inverse:              $app-color-white;
$o-icon-border-width:               1px;
$o-icon-border-style:               solid;
$o-icon-border-color:               currentColor;
$o-icon-border-radius:              50%;



.o-icon {
    display: inline-block;
    fill: currentColor;
    &--default {
        width: $o-icon-width-default;
        height: $o-icon-height-default;
        //margin-bottom: .125rem;
    }
    &--inverse {
        fill: $o-icon-color-inverse;
    }
    &--solid {
        background-color: $o-icon-color-inverse;
    }
    &--primary {
        color: $app-color-primary;
    }
}

.o-icon--circle {
    border: $o-icon-border-width $o-icon-border-style $o-icon-border-color;
    border-radius: $o-icon-border-radius;
}

.o-icon--spacer {
    padding: .6rem;
}

.o-icon--nav-toggle {
    @include icon-variant($app-color-primary, 4rem);
}

//== Icon variant sizes
$icon-sizes: (
    sm: 1.25rem,
    md: 1.5rem,
    lg: 4rem,
    size-1: 1rem,
    size-2: 2rem,
    size-2-5: 2.5rem,
    size-3: 3rem,
    size-4: 4rem,
    size-5: 5rem,
);

@each $icon-size, $size-value in $icon-sizes {
    .o-icon--#{$icon-size} {
        @include icon-variant(currentColor, $size-value);
    }
}
