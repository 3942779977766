.o-brand {
    display: block;
    width: 120px;
    height: 60px;
    margin-left: $app-spacer-sm;
    @include mq($from: md) {
        width: 160px;
        height: 45px;
        margin: $app-spacer-sm 0 $app-spacer-sm $app-spacer-base;
    }
}
