$c-call-out-bg-color:                       $app-color-white;
$c-call-out-border-color:                   $app-color-text;
$c-call-out-border-width:                   .125rem;
$c-call-out-spacer:                         $app-spacer-sm;

.c-call-out {
    background-color: $c-call-out-bg-color;
    padding: $c-call-out-spacer;
    border-left: $c-call-out-border-width solid $c-call-out-border-color;
}
