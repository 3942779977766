p {
    //word-break: break-all;
    //margin-bottom: calc(#{$app-font-size-base} * 1.5);
    @include margin-bottom($app-spacer-base);
    @include when-inside('.c-snippet__section') {
        @include margin-bottom($app-spacer-base);
    }
    &.u-font-test {
        font-family: Trebuchet !important;
    }
}
