$c-control-panel-spacer:                $app-spacer-base;

$c-configlet-spacer-y:                  .5rem;
$c-configlet-spacer-x:                  1rem;
$c-configlet-color:                     $app-color-white;
$c-configlet-bg:                        $app-color-primary;
$c-configlet-font-family-highlight:     $app-font-family-strong;

// Site setup configlets - plone control panel
.c-control-panel {
    margin-bottom: $c-control-panel-spacer;
    &--primary {
        @include mq($from: lg) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.c-control-panel__header {
    display: inline-block;
    padding: $c-configlet-spacer-y $c-configlet-spacer-x;
    margin-top: 3rem;
    color: $c-configlet-color;
    background: $c-configlet-bg;
    font-family: $c-configlet-font-family-highlight;
    @include font-size($app-font-size-h2);
    text-transform: none;
    line-height: 1;
    @include mq($from: lg) {
        flex: 1 0 100%;
        max-width: 100%;
    }
}

.c-control-panel__title {
    color: $app-color-white;
}

.c-control-panel__main {
    padding: $c-control-panel-spacer $c-control-panel-spacer $c-control-panel-spacer 0;
    @include mq($from: lg) {
        flex: 1 0 75%;
        max-width: 75%;
    }
}

.c-control-panel__aside {
    padding: $c-control-panel-spacer;
    @include mq($from: lg) {
        flex: 1 0 25%;
        max-width: 25%;
    }
}

.c-configlet,
.configlets {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include list-reset();
    > form {
        padding-top: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        float: none !important;
        &:not(:first-child) {
            border-top: 1px solid $app-color-gray;
        }
        > button {
            float: right;
            width: auto;
        }
    }
}
