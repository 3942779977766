// Footer
$app-footer-padding-top:                        0;
$app-footer-padding-right:                      0;
$app-footer-padding-bottom:                     $app-spacer-base;
$app-footer-padding-bottom-lg:                  calc(#{$app-spacer-base} * 2.5);
$app-footer-padding-left:                       0;

$app-footer-color:                              $app-color-white;
$app-footer-background:                         $app-color-primary;

$app-footer-content-padding-top:                0;
$app-footer-content-padding-right:              0;
$app-footer-content-padding-bottom:             0;
$app-footer-content-padding-left:               0;

.app-footer {
    width: 100%;
    padding: $app-footer-padding-top $app-footer-padding-right $app-footer-padding-bottom $app-footer-padding-top;
}

.app-footer__content {
    padding: $app-footer-content-padding-top $app-footer-content-padding-right $app-footer-content-padding-bottom $app-footer-content-padding-top;
    &--primary {
        color: $app-color-white;
        background-color: $app-color-primary;
    }
}

.app-footer__main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    @include padding-right($app-spacer-base);
    &--primary {
        color: $app-color-white;
        background-color: $app-color-primary;
    }
}

.app-footer__aside {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include make-container();
    @include make-container-max-widths();
    @include mq($from: lg) {
        padding: 0;
    }
}

.app-footer__copyright {
    @include font-size($app-font-size-sm);
}
