//== Colors
//
//## Brand colors used across the project
$color-st-tropaz: rgb(35, 68, 147) !default; // #234493

$color-powderblue:              rgba(176,224,230,1) !default; // #B0E0E6

$color-shiraz:                  rgba(175, 12, 65, 1) !default; // #af0c41

// Light blue gray body background
$color-mercury:                  rgba(231, 231, 231, 1); // #E7E7E7

// App blue
$color-seagull:                 rgba(116, 196, 234, 1); // #74c4ea
$color-matisse:                 rgba(31, 127, 162, 1); // #1f7fa2
$color-orient:                  rgba(0, 95, 129, 1); // #005f81
// App brown
$color-brandy-punch:            rgba(211, 128, 42, 1); // #d3802a
$color-mai-tai:                 rgba(175, 98, 3, 1);  // #af6203
$color-brown:                   rgba(138, 68, 0, 1); // ##8a4400


// Gray
$color-mine-shaft:              rgba(44, 44, 44, 0);  // #2c2c2c
$color-shark:                   rgba(33, 37, 41, 1); // #212529
$color-shuttle-gray:            rgba(90, 95, 100, 1); // #5a5f64;
$color-iron:                    rgba(227, 228, 229, 1); // #e3e4e5;

// Adjusted brand color versions for better contrast and visibility
$color-midnight-blue:               rgba(0, 51, 102, 1); // #003366;
$color-sun-glow:                    rgba(255, 204, 51, 1); // #ffcc33
$color-tundora:                     rgba(64, 64, 64, 1); // #404040
$color-shuttle-gray:                rgba(90, 95, 100, 1); // #5A5F64
$color-dusty-gray:                  rgba(153, 153, 153, 1); // #999999
$color-gallery:                     rgba(238, 238, 238, 1); // #5A5F64
$color-wild-sand:                   rgba(245, 245, 245, 1); // #f5f5f5
$color-alto:						rgba(224, 224, 224, 1); // #e0e0e0

$app-color-blue-primary:            rgba(5, 81, 125, 1);
$app-color-blue-secondary:          rgba(55, 116, 151, 1);
$app-color-brown:                   rgba(159, 123, 15, 1);
$app-color-green:                   rgba(9, 130, 39, 1);
$app-color-red:                     rgba(144, 7, 7, 1);
$app-color-violet:                  rgba(71, 19, 123, 1);
$app-color-purple:                  rgba(126, 4, 167, 1);

// Gray baseline
$app-color-gray-base:                       $color-gallery;
$app-color-gray:                            $color-shuttle-gray;
$app-color-gray-dropdown:                   $color-alto;
$app-color-gray-light:				        $color-gallery;
$app-color-gray-lighter:				    $color-dusty-gray;
$app-color-gray-lightest:				    $color-wild-sand;

$app-color-white:                        #fff !default;

// Main text color value (initially setup to mirror bootstraps gray 900 color
$app-color-text:                        $color-shark;

// Alternative contrast colors used with the main project color backgrounds
$app-color-gray-contrast:               $color-tundora;
$app-color-gray-contrast-secondary:     $color-shuttle-gray;

//== Brand and Section specific variables
//
//## Used in mixin and modifier classes

//** Primary brand color
$app-color-primary-base:              $color-shark !default;
$app-color-primary-darker:            darken($app-color-primary-base, 15%)  !default;
$app-color-primary-dark:              darken($app-color-primary-base, 7.5%) !default;
$app-color-primary:                   $app-color-primary-base               !default; // #274d38
$app-color-primary-light:             lighten($app-color-primary-base, 7.5%) !default;
$app-color-primary-lighter:           lighten($app-color-primary-base, 15%) !default;

//** Secondary brand color
$app-color-secondary-base:              $color-shuttle-gray !default;
$app-color-secondary-darker:            darken($app-color-secondary-base, 15%)  !default;
$app-color-secondary-dark:              darken($app-color-secondary-base, 7.5%) !default;
$app-color-secondary:                   $app-color-secondary-base               !default; // #588c73
$app-color-secondary-light:             lighten($app-color-secondary-base, 7.5%) !default;
$app-color-secondary-lighter:           lighten($app-color-secondary-base, 15%) !default;


//** Brand primary color schema used as default
$app-color-default:                 $app-color-primary-base;
$app-color-danger:                  $color-seagull;
