i, em {
  font-family: $app-font-family-italic;
  font-weight: $app-font-weight-italic;
  color: $app-color-secondary;
  @include when-inside('.font__primary--loaded') {
    font-weight: $app-font-weight-italic-custom;
  }
}

h1, h2, h3, h4, h5, h6 {
  i, em {
    font-family: $app-headings-font-family;
  }
}