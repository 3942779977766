img {
    max-width: 100%;
    object-fit: contain;
}

//== Lazy load image
img[data-sizes="auto"] {
    display: block;
    // width: 100%;
}

.o-image {
    @include when-inside('.c-cover-image') {
        object-fit: contain;
    }
}


//== Image blur effect
// Reveal low quality image placeholders by applying a filter and
// removing blur when the high quality images have lazy loaded
.o-image--lazy-load {
    opacity: 0;
    transition: opacity 200ms;
    //filter: blur(2px);
    //transition: filter 200ms;
}

.o-image--loaded {
    //filter: blur(0);
    opacity: 1;
}

.o-image--inline {
    margin-bottom: $app-spacer-base;
    margin-right: $app-spacer-base;
}
