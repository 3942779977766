.o-figure {
    display: block;
    margin-bottom: 0;
    figcaption {
        font-size: $app-font-size-sm;
        color: $app-color-gray-lighter;
    }
}

.o-figure__placeholder {
    width: 100%;
    height: 100%;
    background-color: $app-color-gray-light;
}

//== Page figure element
// Embedded media variant
.o-figure__embedded {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
        display: block;
        content: "";
    }

    iframe,
    embed,
    object,
    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        margin-bottom: 1rem;
    }
}

.o-figure__embedded--21by9 {
    &::before {
        padding-top: percentage(9 / 21);
    }
}

.o-figure__embedded--16by9 {
    &::before {
        padding-top: percentage(9 / 16);
    }
}

.o-figure__embedded--4by3 {
    &::before {
        padding-top: percentage(3 / 4);
    }
}

.o-figure__embedded--1by1 {
    &::before {
        padding-top: percentage(1 / 1);
    }
}
