.o-burger {
    position: $o-burger-position;
    top: calc(#{$app-spacer-base} * .75);
    right: $app-spacer-sm;
    z-index: 9001;
    display: $o-burger-display;
    margin-top: $o-burger-margin-top;
    @include mq($from: md) {
        margin-top: 0;
        right: $app-spacer-base;
    }
    @include mq($from: navbar) {
        display: none;
        top: $app-spacer-base;
        right: $app-spacer-base;
    }
}

.o-burger__button {
    display: flex;
    flex-direction: $o-burger-flex-direction;
    line-height: 1;
    background: $o-burger-background-color; // remove default button style
    border: $o-burger-border-width $o-burger-border-style $o-burger-border-color; // remove default button style
    cursor: pointer;
    width: 100%;
    padding: 0 $app-spacer-base 0 0;
    @include mq($from: lg) {
        padding-right: 0;
    }
}

.o-burger__text {
    align-self: flex-end;
    font-family: $o-burger-font;
    font-size: $app-font-size-sm;
    font-weight: $app-font-weight-base-custom;
    color: $o-burger-text-color;
    text-transform: $o-burger-text-transform;
    padding-bottom: $o-burger-padding-y;
    @include mq($from: md) {
        font-size: $o-burger-text-font-size;
        padding-bottom: 0;
    }
    @include when-inside('.js-nav-toggle--active') {
        color: $o-burger-text-color-active;
    }
}

.o-burger__icon {
    margin-left: .5rem;
    // margin-bottom: -.25rem;
    font-size: $o-burger-icon-font-small;
    color: $o-burger-icon-color;
    @include mq($from: md) {
        font-size: $o-burger-icon-font-size;
    }
    @include when-inside('.js-nav-toggle--active') {
        color: $o-burger-text-color-active;
    }
}

.o-burger__icon--default {
    width: $o-burger-layer-width;
    height: $o-burger-layer-height * 3 + $o-burger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

// Actual burger symbol with animation
.o-burger__symbol {
    content: "";
    display: block;
    top: 50%;
    margin-top: $o-burger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $o-burger-layer-width;
        height: $o-burger-layer-height;
        background-color: $o-burger-layer-color;
        border-radius: $o-burger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($o-burger-layer-spacing + $o-burger-layer-height) * -1;
    }

    &::after {
        bottom: ($o-burger-layer-spacing + $o-burger-layer-height) * -1;
    }
}

.o-burger__symbol--animated {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    @include when-inside('.js-nav-toggle--active') {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        background-color: $o-burger-layer-color-active;
    }

    &::before {
        transition: top 0.075s 0.12s ease,
                    opacity 0.075s ease;
        @include when-inside('.js-nav-toggle--active') {
            top: 0;
            opacity: 0;
            transition: top 0.075s ease,
                        opacity 0.075s 0.12s ease;
            background-color: $o-burger-layer-color-active;
        }
    }

    &::after {
        transition: bottom 0.075s 0.12s ease,
                    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        @include when-inside('.js-nav-toggle--active') {
            bottom: 0;
            transform: rotate(-90deg);
            transition: bottom 0.075s ease,
                        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            background-color: $o-burger-layer-color-active;
        }
    }
}

// Fallback content
.o-burger__symbol-content {
    display: none;
}
