.animated,
.u-animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes dimmer-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dim-in {
  opacity: 0;
  /* make things invisible upon start */
  animation: dimmer-animation ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

.dim-in.dim-in-1 {
  transition-delay: 0.5s;
}

.dim-in.dim-in-2 {
  transition-delay: 1.2s;
}

.dim-in.dim-in-3 {
  transition-delay: 1.8s;
}

.app-dlay-1 {
  transition-delay: 0.5s;
}

.app-dlay-2 {
  transition-delay: 1.2s;
}

.app-dlay-3 {
  transition-delay: 1.8s;
}

@-o-keyframes backgroundFade {
  0% {
    background-color: #FFFFFF;
  }
  50% {
    background-color: #AD301B;
  }
  100% {
    background-color: #FFFFFF;
  }
}

@keyframes backgroundFade {
  0% {
    background-color: #FFFFFF;
  }
  50% {
    background-color: #AD301B;
  }
  100% {
    background-color: #FFFFFF;
  }
}

.backgroundAnimated {
  background-image: none !important;
  -o-animation: backgroundFade 5s ease-in-out;
  animation: backgroundFade 5s ease-in-out;
}

@keyframes grow {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.u-animation--grow {
  animation-name: grow;
}

/* Taz-SemiLight & Taz-SemiBold */
/* lora-regular - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../assets/fonts/lora-v17-latin-regular.woff2") format("woff2"), url("../assets/fonts/lora-v17-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lora-700 - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../assets/fonts/lora-v17-latin-700.woff2") format("woff2"), url("../assets/fonts/lora-v17-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lora-italic - latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../assets/fonts/lora-v17-latin-italic.woff2") format("woff2"), url("../assets/fonts/lora-v17-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../assets/fonts/montserrat-v15-latin-300.woff2") format("woff2"), url("../assets/fonts/montserrat-v15-latin-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../assets/fonts/montserrat-v15-latin-regular.woff2") format("woff2"), url("../assets/fonts/montserrat-v15-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../assets/fonts/montserrat-v15-latin-900.woff2") format("woff2"), url("../assets/fonts/montserrat-v15-latin-900.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.hll {
  background-color: #ffffcc;
}

.c {
  color: #999;
}

.err {
  color: #AA0000;
  background-color: #FFAAAA;
}

.k {
  color: #006699;
}

.o {
  color: #555555;
}

.cm {
  color: #999;
}

.cp {
  color: #009999;
}

.c1 {
  color: #999;
}

.cs {
  color: #999;
}

.gd {
  background-color: #FFCCCC;
  border: 1px solid #CC0000;
}

.ge {
  font-style: italic;
}

.gr {
  color: #FF0000;
}

.gh {
  color: #003300;
}

.gi {
  background-color: #CCFFCC;
  border: 1px solid #00CC00;
}

.go {
  color: #AAAAAA;
}

.gp {
  color: #000099;
}

.gu {
  color: #003300;
}

.gt {
  color: #99CC66;
}

.kc {
  color: #006699;
}

.kd {
  color: #006699;
}

.kn {
  color: #006699;
}

.kp {
  color: #006699;
}

.kr {
  color: #006699;
}

.kt {
  color: #007788;
}

.m {
  color: #FF6600;
}

.s {
  color: #d44950;
}

.na {
  color: #4f9fcf;
}

.nb {
  color: #336666;
}

.nc {
  color: #00AA88;
}

.no {
  color: #336600;
}

.nd {
  color: #9999FF;
}

.ni {
  color: #999999;
}

.ne {
  color: #CC0000;
}

.nf {
  color: #CC00FF;
}

.nl {
  color: #9999FF;
}

.nn {
  color: #00CCFF;
}

.nt {
  color: #2f6f9f;
}

.nv {
  color: #003333;
}

.ow {
  color: #000000;
}

.w {
  color: #bbbbbb;
}

.mf {
  color: #FF6600;
}

.mh {
  color: #FF6600;
}

.mi {
  color: #FF6600;
}

.mo {
  color: #FF6600;
}

.sb {
  color: #CC3300;
}

.sc {
  color: #CC3300;
}

.sd {
  color: #CC3300;
  font-style: italic;
}

.s2 {
  color: #CC3300;
}

.se {
  color: #CC3300;
}

.sh {
  color: #CC3300;
}

.si {
  color: #AA0000;
}

.sx {
  color: #CC3300;
}

.sr {
  color: #33AAAA;
}

.s1 {
  color: #CC3300;
}

.ss {
  color: #FFCC33;
}

.bp {
  color: #336666;
}

.vc {
  color: #003333;
}

.vg {
  color: #003333;
}

.vi {
  color: #003333;
}

.il {
  color: #FF6600;
}

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

@supports not (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.ratio-container {
  position: relative;
}

.ratio-container::after {
  content: '';
  display: block;
  height: 0;
  width: 100%;
  content: "";
}

.ratio-container-base::after {
  /* 1:1 = 100% = calc(1 / 1 * 100%) */
  padding-bottom: 100%;
}

.ratio-container-2-1::after {
  /* 2:1 = 50% = calc(1 / 2 * 100%) */
  padding-bottom: 50%;
}

.ratio-container-1-2::after {
  /* 1:2 = 200% = calc(2 / 1 * 100%) */
  padding-bottom: 200%;
}

.ratio-container-4-3::after {
  /* 4:3 = 75% = calc(3 / 4 * 100%) */
  padding-bottom: 75%;
}

.ratio-container-16-9::after {
  /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
  padding-bottom: 56.25%;
}

.ratio-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.unknown-ratio-container > * {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #212529;
  --bs-secondary: #5a5f64;
  --bs-danger: #74c4ea;
  --bs-font-sans-serif: "Montserrat", "Lucida Grande", Tahoma, Sans-Serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Lora", "Lora", "Times New Roman", Times, Georgia, serif;
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media (min-width: 1200px) {
  body {
    font-size: 1.5rem;
  }
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Lora", "Montserrat", "Lucida Grande", Tahoma, Sans-Serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.5rem + 3vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.75rem;
  }
}

h2, .h2 {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 3rem;
  }
}

h3, .h3 {
  font-size: calc(1.3875rem + 1.65vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2.625rem;
  }
}

h4, .h4 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 2.25rem;
  }
}

h5, .h5 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.875rem;
  }
}

h6, .h6 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h6, .h6 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #212529;
  text-decoration: underline;
}

a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none;
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
.input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
.input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-secondary {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #4d5155;
  border-color: #484c50;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #4d5155;
  border-color: #484c50;
  box-shadow: 0 0 0 0.25rem rgba(115, 119, 123, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #484c50;
  border-color: #44474b;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(115, 119, 123, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.btn-danger {
  color: #000;
  background-color: #74c4ea;
  border-color: #74c4ea;
}

.btn-danger:hover {
  color: #000;
  background-color: #89cded;
  border-color: #82caec;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #000;
  background-color: #89cded;
  border-color: #82caec;
  box-shadow: 0 0 0 0.25rem rgba(99, 167, 199, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #90d0ee;
  border-color: #82caec;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(99, 167, 199, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #000;
  background-color: #74c4ea;
  border-color: #74c4ea;
}

.btn-outline-primary {
  color: #212529;
  border-color: #212529;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #5a5f64;
  border-color: #5a5f64;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(90, 95, 100, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(90, 95, 100, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #5a5f64;
  background-color: transparent;
}

.btn-outline-danger {
  color: #74c4ea;
  border-color: #74c4ea;
}

.btn-outline-danger:hover {
  color: #000;
  background-color: #74c4ea;
  border-color: #74c4ea;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(116, 196, 234, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #74c4ea;
  border-color: #74c4ea;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(116, 196, 234, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #74c4ea;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0a58ca;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.c-password-toggle {
  padding-top: .25rem;
  text-align: right;
  font-size: 1rem;
}

/*	Hide show/hide labels by default */
.x-ray,
.x-ray-show,
.x-ray-hide {
  display: none;
  visibility: hidden;
}

/*	Display show/hide toggle when modern JS API's supported.
 *	Display show/hide labels when active. */
.js-x-ray .x-ray,
.x-ray-show.active,
.x-ray-hide.active {
  display: inline;
  visibility: visible;
}

/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: calc(1.5rem * 1.5);
  font-size: 1.5rem;
  font-family: "Lora";
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eeeeee;
  cursor: not-allowed;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #DDDDDD;
  background-color: #FFFFFF;
  margin: 0;
}

.choices[data-type*="select-one"] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: .5;
}

.choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #212529;
}

.choices[data-type*="select-one"]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #212529 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: transparent transparent #212529 transparent;
  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #0a0c0d;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: .75;
  border-radius: 0;
}

.choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #fff;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #DDDDDD;
  border-radius: 0;
  font-size: 1rem;
  min-height: 44px;
  overflow: hidden;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 0 0 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 0 0;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}

[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: calc(1.5rem * 1.5);
  padding: 4px 10px;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #212529;
  border: 1px solid #16181b;
  color: #FFFFFF;
  word-break: break-all;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #16181b;
  border: 1px solid #0a0c0d;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  word-break: break-all;
}

.choices__list--dropdown.is-active {
  display: block;
}

.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: .25rem .25rem 0 0;
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 1rem;
}

[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 1rem;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: .5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: .5;
}

.choices__heading {
  font-weight: 600;
  font-size: 1rem;
  padding: 10px;
  border-bottom: 1px solid #f7f6f6;
  color: #807f7f;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #fff;
  font-size: 1.5rem;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}

.choices__input:focus {
  outline: 0;
}

[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: .5;
}

.choices__input.is-hidden,
.choices[data-type*="select-one"] .choices__input.is-hidden,
.choices[data-type*="select-multiple"] .choices__input.is-hidden {
  display: none;
}

/*=====  End of Choices  ======*/
html {
  overflow-y: scroll;
  /* [2] */
  min-height: 100%;
  /* [3] */
  color: #212529;
  width: 100%;
  font-family: "Lora";
  font-weight: 300;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: #212529;
  background: #fff;
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 500;
}

@media (min-width: 1200px) {
  body {
    font-size: 1.5rem;
  }
}

.fonts--loaded body {
  font-weight: 500;
}

body.u-no-scroll {
  /* disable scrollbar on both x and y axis */
  overflow: hidden;
  /* disable scroll */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* OPTIONAL: none of dom element will be click-able */
  pointer-events: none;
}

body:not(.mce-content-body) {
  background-color: #fff;
}

body.mce-content-body {
  padding: 1rem !important;
  background: #fff !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(33, 37, 41, 0.1);
}

b,
strong {
  font-weight: 700;
}

.font__primary--loaded b, .font__primary--loaded
strong {
  font-weight: 700;
}

i, em {
  font-family: "Lora";
  font-weight: 400;
  color: #5a5f64;
}

.font__primary--loaded i, .font__primary--loaded em {
  font-weight: 500;
}

h1 i, .h1 i, h1 em, .h1 em, h2 i, .h2 i, h2 em, .h2 em, h3 i, .h3 i, h3 em, .h3 em, h4 i, .h4 i, h4 em, .h4 em, h5 i, .h5 i, h5 em, .h5 em, h6 i, .h6 i, h6 em, .h6 em {
  font-family: "Montserrat";
}

button {
  appearance: none;
  background: transparent;
}

a:not(class) {
  color: #212529;
}

a:not(class):hover {
  color: black;
  transition: color 0.5s linear;
}

.o-page__body-text a:not(class) {
  font-weight: 600;
  color: #5a5f64;
}

.o-page__body-text a:not(class):hover {
  color: #484c50;
}

.c-text-block a:not(class) {
  font-weight: 600;
  color: #5a5f64;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.c-text-block a:not(class):hover {
  color: #484c50;
}

img {
  max-width: 100%;
  object-fit: contain;
}

img[data-sizes="auto"] {
  display: block;
}

.c-cover-image .o-image {
  object-fit: contain;
}

.o-image--lazy-load {
  opacity: 0;
  transition: opacity 200ms;
}

.o-image--loaded {
  opacity: 1;
}

.o-image--inline {
  margin-bottom: calc(1.5rem * 1.5);
  margin-right: calc(1.5rem * 1.5);
}

p {
  margin-bottom: calc(1.5rem * 1.5);
}

.c-snippet__section p {
  margin-bottom: calc(1.5rem * 1.5);
}

p.u-font-test {
  font-family: Trebuchet !important;
}

ul {
  list-style: disc;
  margin-bottom: calc(1.5rem * 1.5);
  padding-left: 1rem;
}

@media (min-width: 48em) {
  ul {
    padding-left: calc(1.5rem * 2);
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.25rem;
  font-family: "Montserrat";
  font-weight: 300;
  line-height: 1.2;
  color: #212529;
  text-transform: uppercase;
}

h3, .h3, h4, .h4, h5, .h5, h6, .h6,
.h3, .h4, .h5, .h6 {
  font-family: "Montserrat";
}

h1, .h1, .h1 {
  font-size: calc(1.5rem + 3vw);
}

@media (min-width: 1200px) {
  h1, .h1, .h1 {
    font-size: 3.75rem;
  }
}

h2, .h2, .h2 {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {
  h2, .h2, .h2 {
    font-size: 3rem;
  }
}

h3, .h3, .h3 {
  font-size: calc(1.3875rem + 1.65vw);
}

@media (min-width: 1200px) {
  h3, .h3, .h3 {
    font-size: 2.625rem;
  }
}

h4, .h4, .h4 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
  h4, .h4, .h4 {
    font-size: 2.25rem;
  }
}

h5, .h5, .h5 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  h5, .h5, .h5 {
    font-size: 1.875rem;
  }
}

h6, .h6, .h6 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h6, .h6, .h6 {
    font-size: 1.5rem;
  }
}

table,
.o-table {
  width: 100%;
  margin-bottom: calc(1.5rem * 1.5);
  color: #212529;
  background-color: transparent;
}

table th,
table td,
.o-table th,
.o-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eeeeee;
}

@media (max-width: 61.99em) {
  table th:first-child,
  table td:first-child,
  .o-table th:first-child,
  .o-table td:first-child {
    padding-left: 0;
  }
}

table thead th,
.o-table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eeeeee;
}

table tbody + tbody,
.o-table tbody + tbody {
  border-top: 2px solid #eeeeee;
}

.o-table__container {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.o-table__container > .o-table--bordered {
  border: 0;
}

.o-table--sm th,
.o-table--sm td {
  padding: 0.3rem;
}

.o-table__row--actions {
  border-top: 0.25rem solid #212529;
  padding-top: calc(1.5rem * 1.5);
}

.o-table__cell--actions {
  width: 20%;
  padding-top: calc(1.5rem * 1.5 / 2) !important;
}

.o-table__cell--primary {
  width: 40%;
}

blockquote {
  font-size: 1.5rem;
  font-family: Georgia;
  font-weight: 300;
  font-style: italic;
}

.o-page {
  display: block;
  position: relative;
  padding-right: .25rem;
  padding-left: .25rem;
  width: 100%;
}

@media (min-width: 62em) {
  .o-page {
    padding-top: calc(1.5rem * 1.5 * 1.25);
    padding-right: calc(1.5rem * 1.5);
    padding-left: calc(1.5rem * 1.5);
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 62em) and (min-width: 62em) {
  .o-page {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 62em) and (min-width: 62em) and (min-width: 992px) {
  .o-page {
    max-width: 960px;
  }
}

@media (min-width: 62em) and (min-width: 62em) and (min-width: 1200px) {
  .o-page {
    max-width: 1140px;
  }
}

body:not(.portaltype-ade25-panelpage-page) .o-page:not(.o-page--panels) {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 62em) {
  body:not(.portaltype-ade25-panelpage-page) .o-page:not(.o-page--panels) {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 62em) and (min-width: 992px) {
  body:not(.portaltype-ade25-panelpage-page) .o-page:not(.o-page--panels) {
    max-width: 960px;
  }
}

@media (min-width: 62em) and (min-width: 1200px) {
  body:not(.portaltype-ade25-panelpage-page) .o-page:not(.o-page--panels) {
    max-width: 1140px;
  }
}

.o-page--panels {
  padding-left: 0;
  padding-right: 0;
}

.userrole-authenticated .o-page--panels {
  padding-top: 4rem;
}

.o-page--sign-in {
  display: flex;
  max-width: 100% !important;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.o-page__header {
  margin-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .o-page__header-action {
    text-align: right;
  }
}

.o-page__main {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.o-page__section {
  position: relative;
  margin-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .template-course-view .o-page__section {
    width: 80%;
  }
}

.o-page__section--spacer {
  margin-bottom: calc(calc(1.5rem * 1.5) * 2);
}

.o-page__headline {
  padding: .125rem 0;
  color: #000;
  line-height: 1.25;
}

.o-page__asset {
  width: 100%;
}

@media (max-width: 47.99em) {
  .o-page__asset--md {
    max-width: 50%;
  }
}

.o-page__asset--inline {
  margin-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 48em) {
  .o-page__asset--inline {
    margin-right: calc(1.5rem * 1.5 / 2);
  }
}

.o-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.o-grid--baseline {
  align-content: baseline;
}

.o-grid--gutter {
  width: 100%;
}

@media (min-width: 36em) {
  .o-grid--gutter {
    margin-right: -calc(1.5rem * 1.5 / 2);
    margin-left: -calc(1.5rem * 1.5 / 2);
  }
}

@media (min-width: 62em) {
  .o-grid--reverse {
    flex-direction: row-reverse;
  }
}

@media (min-width: 36em) {
  .o-grid--gutter .o-grid__item {
    padding-right: calc(1.5rem * 1.5 / 2);
    padding-left: calc(1.5rem * 1.5 / 2);
  }
}

.o-grid__item--width-10 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-10 {
  flex: 1 0 10%;
  max-width: 10%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-10 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-10 {
    flex: 1 0 10%;
    max-width: 10%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-10 {
    flex: 1 0 10%;
    max-width: 10%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-10 {
    flex: 1 0 10%;
    max-width: 10%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-10 {
    flex: 1 0 10%;
    max-width: 10%;
  }
}

.o-grid__item--width-16 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-16 {
  flex: 1 0 16.6666%;
  max-width: 16.6666%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-16 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-16 {
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-16 {
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-16 {
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-16 {
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
  }
}

.o-grid__item--width-20 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-20 {
  flex: 1 0 20%;
  max-width: 20%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-20 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-20 {
    flex: 1 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-20 {
    flex: 1 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-20 {
    flex: 1 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-20 {
    flex: 1 0 20%;
    max-width: 20%;
  }
}

.o-grid__item--width-25 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-25 {
  flex: 1 0 25%;
  max-width: 25%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-25 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-25 {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

.o-grid__item--width-30 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-30 {
  flex: 1 0 30%;
  max-width: 30%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-30 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-30 {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-30 {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-30 {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-30 {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

.o-grid__item--width-33 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-33 {
  flex: 1 0 33.3333%;
  max-width: 33.3333%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-33 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-33 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-33 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-33 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-33 {
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

.o-grid__item--width-40 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-40 {
  flex: 1 0 40%;
  max-width: 40%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-40 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-40 {
    flex: 1 0 40%;
    max-width: 40%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-40 {
    flex: 1 0 40%;
    max-width: 40%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-40 {
    flex: 1 0 40%;
    max-width: 40%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-40 {
    flex: 1 0 40%;
    max-width: 40%;
  }
}

.o-grid__item--width-50 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-50 {
  flex: 1 0 50%;
  max-width: 50%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-50 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

.o-grid__item--width-60 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-60 {
  flex: 1 0 60%;
  max-width: 60%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-60 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-60 {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-60 {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-60 {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-60 {
    flex: 1 0 60%;
    max-width: 60%;
  }
}

.o-grid__item--width-66 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-66 {
  flex: 1 0 66.6666%;
  max-width: 66.6666%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-66 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-66 {
    flex: 1 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-66 {
    flex: 1 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-66 {
    flex: 1 0 66.6666%;
    max-width: 66.6666%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-66 {
    flex: 1 0 66.6666%;
    max-width: 66.6666%;
  }
}

.o-grid__item--width-75 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-75 {
  flex: 1 0 75%;
  max-width: 75%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-75 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-75 {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-75 {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-75 {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-75 {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

.o-grid__item--width-100 {
  flex: 1 0 100%;
}

.mce-content-body .o-grid__item--width-100 {
  flex: 1 0 100%;
  max-width: 100%;
}

@media (min-width: 48em) {
  .o-grid--intermediate .o-grid__item--width-100 {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 36em) {
  .o-grid--sm .o-grid__item--width-100 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 48em) {
  .o-grid--md .o-grid__item--width-100 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 62em) {
  .o-grid--lg .o-grid__item--width-100 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 75em) {
  .o-grid__item--width-100 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.o-brand {
  display: block;
  width: 120px;
  height: 60px;
  margin-left: calc(1.5rem * 1.5 / 2);
}

@media (min-width: 48em) {
  .o-brand {
    width: 160px;
    height: 45px;
    margin: calc(1.5rem * 1.5 / 2) 0 calc(1.5rem * 1.5 / 2) calc(1.5rem * 1.5);
  }
}

.o-burger {
  position: fixed;
  top: calc(calc(1.5rem * 1.5) * .75);
  right: calc(1.5rem * 1.5 / 2);
  z-index: 9001;
  display: block;
  margin-top: 0;
}

@media (min-width: 48em) {
  .o-burger {
    margin-top: 0;
    right: calc(1.5rem * 1.5);
  }
}

@media (min-width: 62499.9375em) {
  .o-burger {
    display: none;
    top: calc(1.5rem * 1.5);
    right: calc(1.5rem * 1.5);
  }
}

.o-burger__button {
  display: flex;
  flex-direction: row;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  width: 100%;
  padding: 0 calc(1.5rem * 1.5) 0 0;
}

@media (min-width: 62em) {
  .o-burger__button {
    padding-right: 0;
  }
}

.o-burger__text {
  align-self: flex-end;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 500;
  color: #212529;
  text-transform: uppercase;
  padding-bottom: 0.25rem;
}

@media (min-width: 48em) {
  .o-burger__text {
    font-size: 1.45rem;
    padding-bottom: 0;
  }
}

.js-nav-toggle--active .o-burger__text {
  color: #fff;
}

.o-burger__icon {
  margin-left: .5rem;
  font-size: 1rem;
  color: #212529;
}

@media (min-width: 48em) {
  .o-burger__icon {
    font-size: 1.5rem;
  }
}

.js-nav-toggle--active .o-burger__icon {
  color: #fff;
}

.o-burger__icon--default {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.o-burger__symbol {
  content: "";
  display: block;
  top: 50%;
  margin-top: -2px;
}

.o-burger__symbol, .o-burger__symbol::before, .o-burger__symbol::after {
  width: 40px;
  height: 4px;
  background-color: #212529;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.o-burger__symbol::before, .o-burger__symbol::after {
  content: "";
  display: block;
}

.o-burger__symbol::before {
  top: -10px;
}

.o-burger__symbol::after {
  bottom: -10px;
}

.o-burger__symbol--animated {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.js-nav-toggle--active .o-burger__symbol--animated {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #fff;
}

.o-burger__symbol--animated::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.js-nav-toggle--active .o-burger__symbol--animated::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  background-color: #fff;
}

.o-burger__symbol--animated::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.js-nav-toggle--active .o-burger__symbol--animated::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #fff;
}

.o-burger__symbol-content {
  display: none;
}

.o-icon {
  display: inline-block;
  fill: currentColor;
}

.o-icon--default {
  width: 1rem;
  height: 1rem;
}

.o-icon--inverse {
  fill: #fff;
}

.o-icon--solid {
  background-color: #fff;
}

.o-icon--primary {
  color: #212529;
}

.o-icon--circle {
  border: 1px solid currentColor;
  border-radius: 50%;
}

.o-icon--spacer {
  padding: .6rem;
}

.o-icon--nav-toggle {
  fill: #212529;
  width: 4rem;
  height: 4rem;
}

.o-icon--sm {
  fill: currentColor;
  width: 1.25rem;
  height: 1.25rem;
}

.o-icon--md {
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

.o-icon--lg {
  fill: currentColor;
  width: 4rem;
  height: 4rem;
}

.o-icon--size-1 {
  fill: currentColor;
  width: 1rem;
  height: 1rem;
}

.o-icon--size-2 {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
}

.o-icon--size-2-5 {
  fill: currentColor;
  width: 2.5rem;
  height: 2.5rem;
}

.o-icon--size-3 {
  fill: currentColor;
  width: 3rem;
  height: 3rem;
}

.o-icon--size-4 {
  fill: currentColor;
  width: 4rem;
  height: 4rem;
}

.o-icon--size-5 {
  fill: currentColor;
  width: 5rem;
  height: 5rem;
}

.o-placeholder {
  margin: 0.5rem auto;
  padding: 1rem;
  text-align: center;
}

@media (min-width: 48em) {
  .o-placeholder {
    padding: 2rem 1rem;
    width: 66.66%;
  }
}

.o-placeholder__sign {
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  padding-top: 0.5rem;
  border-radius: 50%;
  border: 1px solid gray("400");
  color: gray("400");
  font-size: 2rem;
}

.o-menu {
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

.o-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #212529;
  font-family: "Lora";
}

.o-dropdown--hidden {
  display: none;
}

.o-dropdown--active {
  display: block;
}

.o-dropdown__list {
  list-style: none;
  padding: 1rem;
  width: auto;
  min-width: 14rem;
  margin-bottom: 0;
}

.o-dropdown__list-item {
  padding: .25rem;
  line-height: 1rem;
}

.o-dropdown__list-item:not(last) {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.o-dropdown__list-item:last-of-type {
  border-bottom: 0px;
}

.o-dropdown__link {
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
}

.o-dropdown__link:hover {
  text-decoration: none;
  color: #fff;
}

.o-dropdown__icon {
  display: inline-block;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 1rem;
  height: 1rem;
  line-height: 1;
  float: right;
}

input, button, select, textarea {
  font-family: "Lora";
}

.o-form {
  width: 100%;
  background-color: #fff;
  padding: calc(1.5rem * 1.5 / 2);
}

.o-form--panels {
  padding: calc(1.5rem * 1.5 / 2);
}

.o-form.pull-right {
  background: transparent;
}

.o-form__group {
  margin-bottom: calc(1.5rem * 1.5);
}

.o-form__field {
  display: block;
}

.o-form__field--hidden {
  opacity: 0;
  display: none;
  height: 0;
}

.o-form__field--spacer {
  margin-bottom: calc(1.5rem * 1.5);
}

.o-form__field--required {
  position: relative;
}

.o-form__required-marker {
  display: inline-block;
  vertical-align: middle;
  margin: -0.25em 0.25em 0em;
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  padding: 0.5em 0.8em;
  color: rgba(0, 0, 0, 0.65);
  text-transform: uppercase;
  font-weight: normal;
  border-radius: 0.325em;
  box-sizing: border-box;
  transition: background 0.1s linear;
  font-size: 75%;
}

.o-form__required-marker {
  background-color: transparent;
  position: absolute;
  top: 1.25rem;
  right: 2rem;
  z-index: 10;
  margin: 0em;
  width: 45px;
  height: 45px;
  padding: 0em;
  text-align: center;
  transition: color 0.2s ease;
}

.o-form__field--checkbox .o-form__required-marker {
  top: -.75rem;
}

.o-form__required-marker:after {
  position: absolute;
  content: "";
  right: 1px;
  top: 1px;
  z-index: -1;
  width: 0em;
  height: 0em;
  transition: border-color 0.2s ease;
}

.o-form__required-marker-text {
  margin: 1rem 0 0 calc(1.5rem * 1.5);
  color: #74c4ea;
  font-size: calc(1.5rem * 2);
  font-weight: 300;
}

.o-form__label {
  font-family: "Lora";
  font-weight: 700;
  line-height: 1.25;
}

.o-form__label--float {
  color: #432e2e;
  box-sizing: border-box;
  display: block;
  font-size: 0.65rem;
  font-weight: normal;
  line-height: 1;
  opacity: 0;
  overflow: hidden;
  padding: .5rem 1rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0rem;
  text-align: left;
  transform: translateY(3px);
  transition: all .2s ease-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.form__field--not-empty .o-form__label--float {
  opacity: 1;
  transform: none;
}

.o-form__group--not-empty .o-form__label--float {
  opacity: 1;
  transform: none;
}

.o-form__label--float--postfix {
  top: 0;
  opacity: 1;
  transform: none;
  padding: 0 0 0 1rem;
  font-size: calc(1.275rem + 0.3vw);
  margin-bottom: .5rem;
}

@media (min-width: 1200px) {
  .o-form__label--float--postfix {
    font-size: 1.5rem;
  }
}

.o-form__help {
  display: block;
  font-family: "Lora";
  font-size: 1rem;
  font-weight: 500;
  color: #5a5f64;
}

.o-form__help--danger {
  color: #74c4ea;
}

.o-form__control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 1200px) {
  .o-form__control {
    font-size: 1.5rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .o-form__control {
    transition: none;
  }
}

.o-form__control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.o-form__control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.o-form__control:disabled, .o-form__control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.o-form__control--hidden {
  display: none !important;
  opacity: 0 !important;
}

.o-form__control--visible {
  display: block !important;
  opacity: 1 !important;
}

.o-form__control--textarea {
  height: auto;
}

.form__field--checkbox .option {
  display: flex;
  margin-right: 1rem;
}

.form__field--checkbox .option > label {
  margin-left: .5rem;
  font-weight: 700;
}

.o-form__control--checkbox {
  position: absolute;
  left: -9999px;
}

.listingCheckbox .o-form__control--checkbox {
  position: initial;
}

.o-form__control--checkbox + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
  font-weight: 400;
}

.o-form__control--checkbox + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #ced4da;
  background: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.o-form__control--checkbox + label:hover:before {
  border: 1px solid #ced4da !important;
}

.o-form__control--checkbox + label:after {
  content: url("../assets/images/ui_check.svg");
  position: absolute;
  top: -0.125rem;
  left: .2rem;
  z-index: 1;
  font-size: 1.3em;
  line-height: 0.8;
  color: #000;
  transition: all .2s;
  background-color: transparent;
}

.o-form__field--required .o-form__control--checkbox + label {
  margin-right: calc(1.5rem * 1.5 * 1.25);
}

/* checked mark aspect changes */
.o-form__control--checkbox:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.o-form__control--checkbox:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.o-form__control--checkbox:checked + label:before {
  background-image: linear-gradient(135deg, #212529, #212529);
}

/* disabled checkbox */
.o-form__control--checkbox:disabled:not(:checked) + label:before,
.o-form__control--checkbox:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.o-form__control--checkbox:disabled:checked + label:after {
  color: #999;
}

.o-form__control--checkbox:disabled + label {
  color: #aaa;
}

/* accessibility */
.o-form__control--checkbox:checked:focus + label:before,
.o-form__control--checkbox:not(:checked):focus + label:before {
  border: 1px solid #ced4da;
}

.o-form__actions {
  padding-top: calc(1.5rem * 1.5);
  padding-bottom: calc(1.5rem * 1.5);
}

.o-form--panels .o-form__actions {
  display: flex;
  justify-content: space-between;
}

.o-form--widget .o-form__actions {
  display: flex;
  justify-content: space-between;
}

.autotoc-section {
  padding-top: calc(1.5rem * 1.5);
}

.pat-relateditems {
  height: auto;
}

/* class applies to select element itself, not a wrapper element */
.o-select {
  display: block;
  font-size: 1.5rem;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

    */
}

.o-select:not([multiple]) {
  background: #fff;
}

.o-select[multiple], .o-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1.5rem;
  background-image: none;
}

/* Hide arrow icon in IE browsers */
.o-select::-ms-expand {
  display: none;
}

/* Hover style */
.o-select:hover {
  border-color: #888;
}

/* Focus style */
.o-select:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.o-select option {
  font-weight: normal;
}

.o-switch {
  position: relative;
  display: block;
  min-height: 2.25rem;
}

.o-switch__control {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.o-switch__label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.o-switch__label:before {
  content: "";
  cursor: pointer;
  text-indent: -9999px;
  width: 200px;
  height: 100px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: absolute;
}

.o-switch__label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.o-switch:checked + .o-switch__label:before {
  background: #bada55;
}

.o-switch:checked + .o-switch__label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.o-switch__label:active:after {
  width: 130px;
}

.o-figure {
  display: block;
  margin-bottom: 0;
}

.o-figure figcaption {
  font-size: 1rem;
  color: #999999;
}

.o-figure__placeholder {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}

.o-figure__embedded {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.o-figure__embedded::before {
  display: block;
  content: "";
}

.o-figure__embedded iframe,
.o-figure__embedded embed,
.o-figure__embedded object,
.o-figure__embedded video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin-bottom: 1rem;
}

.o-figure__embedded--21by9::before {
  padding-top: 42.8571428571%;
}

.o-figure__embedded--16by9::before {
  padding-top: 56.25%;
}

.o-figure__embedded--4by3::before {
  padding-top: 75%;
}

.o-figure__embedded--1by1::before {
  padding-top: 100%;
}

.app-sidebar .o-aside {
  padding-left: calc(1.5rem * 1.5);
  padding-right: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .app-sidebar .o-aside {
    padding: 0;
  }
}

.o-separator {
  position: relative;
  width: 100%;
  padding-bottom: calc(calc(calc(1.5rem * 1.5) * 1.5) / 3.5);
}

.o-separator:not(:last-child)::after, .o-separator--default::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90px;
  height: 0.25rem;
  background-color: #eeeeee;
}

@media (min-width: 62em) {
  .o-separator--sm {
    display: none;
  }
}

.o-link {
  color: #5a5f64;
}

.o-link:hover {
  color: #484c50;
  transition: color 0.5s linear;
}

.o-read-more {
  color: #fff;
}

.o-read-more__text {
  color: #fff;
}

.o-read-more__icon {
  margin-right: .5rem;
}

.o-hero__aside {
  display: none;
}

@media (min-width: 48em) {
  .o-hero__aside {
    display: block;
    padding-left: calc(1.5rem * 1.5);
    padding-right: calc(1.5rem * 1.5);
  }
}

.o-timestamp {
  display: flex;
  margin-right: 0.125rem;
  font-size: 1rem;
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 4rem;
  z-index: 100;
  display: block;
  background-color: #fff;
  border-top: 0 solid #212529;
}

@media (min-width: 768px) {
  .app-header {
    margin: 0 auto 1rem;
  }
  .plone-toolbar-left .app-header {
    left: 60px;
    width: calc(100vw - 60px);
  }
  .plone-toolbar-left-expanded .app-header {
    left: 120px;
    width: calc(100vw - 120px);
  }
}

@media (min-width: 62499.9375em) {
  .app-header {
    min-height: 8rem;
    position: static;
    background-color: transparent;
  }
}

.app-header--offset {
  border-bottom: 1px solid #999999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.c-header {
  padding-top: 1.5rem;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.app-header__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 62499.9375em) {
  .app-header__content {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    padding-top: calc(1.5rem * 1.5);
    padding-bottom: calc(1.5rem * 1.5);
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }
}

.app-header__section {
  display: block;
  position: relative;
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
}

.app-header__section:first-child {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
}

@media (min-width: 62499.9375em) {
  .app-header__section {
    width: 16.6666%;
    flex: 0 0 16.6666%;
  }
  .app-header__section:first-child {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 62499.9375em) and (min-width: 62499.9375em) {
  .app-header__section--burger {
    display: none;
  }
}

.app-header__section--meta-nav {
  display: none;
}

@media (min-width: 62499.9375em) {
  .app-header__section--meta-nav {
    display: block;
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.app-header__section--quick-links {
  display: flex;
}

.app-main {
  position: relative;
  width: 100%;
  padding-top: 8rem;
  padding-bottom: calc(calc(calc(1.5rem * 1.5) * 2) + calc(1.5rem * 1.5 * 1.25));
  margin-left: auto;
  margin-right: auto;
}

.template-frontpage-view .app-main {
  padding-top: 0;
}

.app-main.u-no-scroll {
  position: fixed;
  left: 0;
  right: 0;
  overflow-y: scroll;
  /* render disabled scroll bar to keep the same width */
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .app-main {
    padding-top: calc(8rem + 2rem);
  }
}

@media (min-width: 48em) {
  .app-main {
    background: transparent;
  }
}

@media (min-width: 62499.9375em) {
  .app-main {
    padding-top: 0;
  }
}

.app-main--fullscreen {
  padding: 0;
}

.app-main--grid {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 62em) {
  .app-main--grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

@media (min-width: 62em) {
  .app-main--grid .app-sidebar {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 62em) {
  .app-main--grid .app-content {
    flex: 1 0 75%;
    max-width: 75%;
    padding-right: calc(1.5rem * 1.5);
  }
}

.app-sidebar {
  padding-right: calc(1.5rem * 1.5);
  padding-left: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .app-sidebar {
    padding: 0;
  }
}

.app-footer {
  width: 100%;
  padding: 0 0 calc(1.5rem * 1.5) 0;
}

.app-footer__content {
  padding: 0 0 0 0;
}

.app-footer__content--primary {
  color: #fff;
  background-color: #212529;
}

.app-footer__main {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  padding-right: calc(1.5rem * 1.5);
}

.app-footer__main--primary {
  color: #fff;
  background-color: #212529;
}

.app-footer__aside {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .app-footer__aside {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .app-footer__aside {
    max-width: 1140px;
  }
}

@media (min-width: 62em) {
  .app-footer__aside {
    padding: 0;
  }
}

.app-footer__copyright {
  font-size: 1rem;
}

.c-identifier {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: .25rem 1rem;
  width: 100%;
  display: block;
  color: #fff;
  background-color: #212529;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .plone-toolbar-left .c-identifier {
    left: 60px;
    width: calc(100% - 60px);
  }
  .plone-toolbar-left-expanded .c-identifier {
    left: 120px;
    width: calc(100% - 120px);
  }
}

.c-identifier__content {
  font-size: 1rem;
  font-family: "Lora";
  text-align: right;
}

.c-sign-in {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #eeeeee;
  background: #fff;
}

@media (min-width: 36em) {
  .c-sign-in {
    width: 576px;
  }
}

.c-sign-in__header {
  padding: 2rem;
  text-align: center;
}

.c-button {
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: normal;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-family: "Lora";
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: currentColor;
}

@media (prefers-reduced-motion: reduce) {
  .c-button {
    transition: none;
  }
}

.c-button:hover {
  color: #212529;
}

.btn-check:focus + .c-button, .c-button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.c-button.disabled, .c-button--disabled, .c-button:disabled {
  opacity: .5;
}

.c-button:not(:disabled):not(.disabled):active, .c-button:not(:disabled):not(.disabled).active {
  background-image: none;
}

@media (min-width: 768px) {
  .c-button {
    white-space: nowrap;
  }
}

a.c-button.disabled,
a.c-button--disabled,
fieldset[disabled] a.c-button {
  pointer-events: none;
}

a.c-button--active-action {
  pointer-events: none;
  border-width: 0 0 1px 0;
}

.c-button--default {
  color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--default:hover {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.btn-check:focus + .c-button--default, .c-button--default:focus {
  box-shadow: 0 0 0 0.25rem rgba(90, 95, 100, 0.5);
}

.btn-check:checked + .c-button--default,
.btn-check:active + .c-button--default, .c-button--default:active, .c-button--default.active, .c-button--default.dropdown-toggle.show {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.btn-check:checked + .c-button--default:focus,
.btn-check:active + .c-button--default:focus, .c-button--default:active:focus, .c-button--default.active:focus, .c-button--default.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(90, 95, 100, 0.5);
}

.c-button--default:disabled, .c-button--default.disabled {
  color: #5a5f64;
  background-color: transparent;
}

.searchPage .c-button--default {
  color: #fff;
  border-color: #fff;
}

.searchPage .c-button--default:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus + .searchPage .c-button--default, .searchPage .c-button--default:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}

.btn-check:checked + .searchPage .c-button--default,
.btn-check:active + .searchPage .c-button--default, .searchPage .c-button--default:active, .searchPage .c-button--default.active, .searchPage .c-button--default.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:checked + .searchPage .c-button--default:focus,
.btn-check:active + .searchPage .c-button--default:focus, .searchPage .c-button--default:active:focus, .searchPage .c-button--default.active:focus, .searchPage .c-button--default.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}

.searchPage .c-button--default:disabled, .searchPage .c-button--default.disabled {
  color: #fff;
  background-color: transparent;
}

.searchPage .c-button--default:hover {
  color: #fff;
  background: #32383e;
}

.c-button--danger {
  color: #74c4ea;
  border-color: #74c4ea;
}

.c-button--danger:hover {
  color: #fff;
  background-color: #74c4ea;
  border-color: #74c4ea;
}

.btn-check:focus + .c-button--danger, .c-button--danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(116, 196, 234, 0.5);
}

.btn-check:checked + .c-button--danger,
.btn-check:active + .c-button--danger, .c-button--danger:active, .c-button--danger.active, .c-button--danger.dropdown-toggle.show {
  color: #000;
  background-color: #74c4ea;
  border-color: #74c4ea;
}

.btn-check:checked + .c-button--danger:focus,
.btn-check:active + .c-button--danger:focus, .c-button--danger:active:focus, .c-button--danger.active:focus, .c-button--danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(116, 196, 234, 0.5);
}

.c-button--danger:disabled, .c-button--danger.disabled {
  color: #74c4ea;
  background-color: transparent;
}

.c-button--primary {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.c-button--primary:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus + .c-button--primary, .c-button--primary:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:checked + .c-button--primary,
.btn-check:active + .c-button--primary, .c-button--primary:active, .c-button--primary.active,
.show > .c-button--primary.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:checked + .c-button--primary:focus,
.btn-check:active + .c-button--primary:focus, .c-button--primary:active:focus, .c-button--primary.active:focus,
.show > .c-button--primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.c-button--primary:disabled, .c-button--primary.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.c-button--secondary {
  color: #fff;
  background-color: #5a5f64;
  border-color: #fff;
}

.c-button--secondary:hover {
  color: #fff;
  background-color: #4d5155;
  border-color: #cccccc;
}

.btn-check:focus + .c-button--secondary, .c-button--secondary:focus {
  color: #fff;
  background-color: #4d5155;
  border-color: #cccccc;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}

.btn-check:checked + .c-button--secondary,
.btn-check:active + .c-button--secondary, .c-button--secondary:active, .c-button--secondary.active,
.show > .c-button--secondary.dropdown-toggle {
  color: #fff;
  background-color: #484c50;
  border-color: #bfbfbf;
}

.btn-check:checked + .c-button--secondary:focus,
.btn-check:active + .c-button--secondary:focus, .c-button--secondary:active:focus, .c-button--secondary.active:focus,
.show > .c-button--secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}

.c-button--secondary:disabled, .c-button--secondary.disabled {
  color: #fff;
  background-color: #5a5f64;
  border-color: #fff;
}

.c-button--link {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.c-button--link:hover {
  color: #fff;
  background-color: #4d5155;
  border-color: #484c50;
}

.btn-check:focus + .c-button--link, .c-button--link:focus {
  color: #fff;
  background-color: #4d5155;
  border-color: #484c50;
  box-shadow: 0 0 0 0.25rem rgba(115, 119, 123, 0.5);
}

.btn-check:checked + .c-button--link,
.btn-check:active + .c-button--link, .c-button--link:active, .c-button--link.active,
.show > .c-button--link.dropdown-toggle {
  color: #fff;
  background-color: #484c50;
  border-color: #44474b;
}

.btn-check:checked + .c-button--link:focus,
.btn-check:active + .c-button--link:focus, .c-button--link:active:focus, .c-button--link.active:focus,
.show > .c-button--link.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(115, 119, 123, 0.5);
}

.c-button--link:disabled, .c-button--link.disabled {
  color: #fff;
  background-color: #5a5f64;
  border-color: #5a5f64;
}

.c-button + .c-button {
  margin-left: calc(1.5rem * .5);
}

.c-button--sm {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0;
}

.c-button--lg {
  padding: 0.375rem 0.75rem;
  font-size: calc(1.325rem + 0.9vw);
  border-radius: 0;
}

@media (min-width: 1200px) {
  .c-button--lg {
    font-size: 2rem;
  }
}

.c-button--reorder {
  cursor: move;
}

.c-button--register {
  color: #fff;
}

.c-call-out {
  background-color: #fff;
  padding: calc(1.5rem * 1.5 / 2);
  border-left: 0.125rem solid #212529;
}

.c-feature {
  background-color: transparent;
  padding: calc(1.5rem * 1.5);
  border: 0 solid #5a5f64;
  border-width: 0.25rem 0;
  color: #212529;
  font-weight: bold;
  margin-top: calc(1.5rem * 1.5 * 1.25);
  margin-bottom: calc(1.5rem * 1.5 * 1.25);
}

.c-alert {
  padding: calc(1.5rem * 1.5 / 2) calc(1.5rem * 1.5);
  margin: calc(1.5rem * 1.5 / 2) auto;
  border: 1px solid #eee;
  border-left-width: .25rem;
}

.app-main > .c-alert {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.c-alert__title {
  margin-top: 0;
  margin-bottom: .25rem;
}

.c-alert p:last-child {
  margin-bottom: 0;
}

.c-alert__button {
  float: right;
  margin-bottom: .5rem;
}

.c-alert--default {
  color: #000;
  background-color: #fff;
  border-color: #adb5bd;
}

.c-alert--default .alert-link {
  color: black;
}

.c-alert--success {
  color: #198754;
  background-color: #fff;
  border-color: #198754;
}

.c-alert--success .alert-link {
  color: #146c43;
}

.c-alert--danger {
  color: #dc3545;
  background-color: #fff;
  border-color: #dc3545;
}

.c-alert--danger .alert-link {
  color: #b02a37;
}

.c-alert--warning {
  color: #ffc107;
  background-color: #fff;
  border-color: #ffc107;
}

.c-alert--warning .alert-link {
  color: #cc9a06;
}

.c-alert--primary {
  color: #212529;
  background-color: #fff;
  border-color: #5a5f64;
}

.c-alert--primary .alert-link {
  color: #1a1e21;
}

.c-alert--toast {
  position: fixed;
  top: calc(1.5rem * 1.5 / 2);
  left: calc(1.5rem * 1.5 / 2);
}

.c-testimonials {
  display: block;
}

.c-testimonial {
  display: block;
  position: relative;
  font-family: "Lora";
  font-size: 1rem;
  font-weight: 500;
}

.c-testimonial__main {
  display: flex;
}

.c-testimonial__symbol {
  display: block;
  flex: 1 0 4rem;
  position: relative;
}

.c-testimonial__symbol::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 0.125rem / 2);
  width: 0.125rem;
  height: 100%;
  background-color: #fff;
}

.c-testimonial__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(25% - 2rem);
  left: calc(50% - 2rem);
  width: 4rem;
  height: 4rem;
  background-color: #212529;
}

.o-icon--testimonial {
  fill: currentColor;
  width: 2.5rem;
  height: 2.5rem;
}

.c-control-panel {
  margin-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .c-control-panel--primary {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-control-panel__header {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-top: 3rem;
  color: #fff;
  background: #212529;
  font-family: "Lora";
  font-size: calc(1.425rem + 2.1vw);
  text-transform: none;
  line-height: 1;
}

@media (min-width: 1200px) {
  .c-control-panel__header {
    font-size: 3rem;
  }
}

@media (min-width: 62em) {
  .c-control-panel__header {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.c-control-panel__title {
  color: #fff;
}

.c-control-panel__main {
  padding: calc(1.5rem * 1.5) calc(1.5rem * 1.5) calc(1.5rem * 1.5) 0;
}

@media (min-width: 62em) {
  .c-control-panel__main {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

.c-control-panel__aside {
  padding: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .c-control-panel__aside {
    flex: 1 0 25%;
    max-width: 25%;
  }
}

.c-configlet,
.configlets {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

.c-configlet > form,
.configlets > form {
  padding-top: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  float: none !important;
}

.c-configlet > form:not(:first-child),
.configlets > form:not(:first-child) {
  border-top: 1px solid #5a5f64;
}

.c-configlet > form > button,
.configlets > form > button {
  float: right;
  width: auto;
}

.c-pagination {
  padding: 0.5rem 0;
  margin-bottom: 1.5rem;
}

.c-pagination__list {
  display: flex;
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
  flex-wrap: wrap;
}

.c-pagination__link {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #212529;
  background-color: #fff;
  border: 1px solid #eeeeee;
}

.c-pagination__link:hover {
  z-index: 2;
  color: #212529;
  text-decoration: none;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.c-pagination__link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}

.c-pagination__list-item--previous .c-pagination__link > .label {
  display: none;
}

.c-pagination__list-item--previous .c-pagination__link > .arrow:before {
  content: "\00ab";
}

.c-pagination__list-item--next .c-pagination__link > .label {
  display: none;
}

.c-pagination__list-item--next .c-pagination__link > .arrow:before {
  content: "\00bb";
}

.c-pagination__list-item {
  display: block;
}

.c-pagination__list-item:first-child .page-link {
  margin-left: 0;
}

.c-pagination__list-item--active {
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #212529;
  background-color: #fff;
  border: 1px solid #eeeeee;
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.c-pagination__list-item--active > span {
  z-index: 1;
}

.app-nav-tree {
  display: none;
  background-color: transparent;
  font-weight: 500;
}

@media (min-width: 992px) {
  .app-nav-tree {
    display: block;
  }
}

.app-nav__list {
  padding-left: 0;
  list-style: none;
}

.app-nav__list--level-1, .app-nav__list--level-2 {
  margin-left: .5rem;
}

.app-nav-tree-list {
  padding-left: 0;
  list-style: none;
}

.app-nav-tree-list li {
  width: 100%;
}

.app-nav__list-item {
  text-transform: uppercase;
  margin-left: 0;
  padding: .5rem .25rem;
}

.app-nav__list-item:not(last) {
  margin-bottom: .25rem;
  border-bottom: 1px solid #5a5f64;
}

.app-nav__list-item:last-of-type {
  border-bottom: 0px;
}

.app-nav__list-link {
  color: #000;
}

.app-nav__list-link:hover {
  color: #0a0c0d;
}

.app-nav__list-link.navTreeCurrentNode {
  color: #5a5f64;
}

.app-nav__list-link--open {
  color: #212529;
}

.app-nav__list-link--active {
  color: #212529;
}

.app-nav__list--level-0 {
  margin-bottom: 0;
}

.app-nav__list--level-1 {
  margin-left: 0;
}

.c-nav-bar {
  position: fixed;
  top: 0;
  z-index: 1001;
  width: 90vw;
  height: 100vh;
  overflow-y: scroll;
  right: -100%;
  border-top: 0px solid #212529;
  background-color: #212529;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.25), 0 0.65rem 0.65rem rgba(0, 0, 0, 0.22);
}

.c-nav-bar--overlay {
  pointer-events: all;
  right: 0;
  transition: right .5s linear;
}

@media (min-width: 48em) {
  .c-nav-bar--overlay {
    width: 50vw;
  }
}

@media (min-width: 62499.9375em) {
  .c-nav-bar {
    position: relative;
    display: block;
    right: 0;
    width: 100%;
    min-height: 3rem;
    height: auto;
    flex: 1 0 100%;
    background: #6c757d;
    box-shadow: none;
  }
}

.c-nav-bar--hidden {
  transition: right .5s linear;
}

.c-nav-bar__header {
  display: flex;
  min-height: 8rem;
  padding-left: 1rem;
  padding-right: 0;
  background-color: #212529;
  color: #fff;
  border-bottom: 2px solid #fff;
}

@media (min-width: 48em) {
  .c-nav-bar__header {
    padding-left: 2rem;
    padding-right: 1rem;
  }
}

@media (min-width: 62499.9375em) {
  .c-nav-bar__header {
    display: none;
  }
}

.c-nav-bar__title {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-family: "Lora";
  text-transform: uppercase;
  font-size: 1.25rem;
  color: #212529;
}

.c-nav-bar__toggle {
  width: 4rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  color: #212529;
}

.app-nav__toggle-icon > polygon {
  fill: #212529;
}

.c-nav-bar__main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-nav {
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  overflow: visible;
  padding: 0;
  list-style: none;
  background-color: #212529;
}

@media (min-width: 48em) {
  .c-nav {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 62499.9375em) {
  .c-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 0;
    align-items: stretch;
    justify-content: space-between;
  }
}

.c-nav--level-1 {
  position: relative;
  overflow-y: visible;
}

.c-nav__item {
  position: relative;
  overflow: visible;
  width: 100%;
  margin: 0 0 calc(1.5rem * 1.5 / 2);
  padding: 0 0 0 calc(1.5rem * 1.5 / 2);
  background-color: #212529;
}

@media (min-width: 62499.9375em) {
  .c-nav__item {
    display: flex;
    align-items: center;
    width: auto;
    flex: 1 0 0;
    padding: 1rem 0;
    border-left: 1px solid #fff;
    margin: 0;
  }
  .c-nav__item:last-child {
    border-right: 1px solid #fff;
  }
}

.c-nav__item--back-link, .c-nav__item--parent {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  display: flex;
  padding: 0;
}

.c-nav__dropdown--open .c-nav__item {
  padding-left: 1rem;
}

.c-nav__dropdown--open .c-nav__item:last-child {
  border-bottom: 0px solid #6c757d;
}

.c-nav__item--has-children {
  position: relative;
}

.c-nav__link {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: .75rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
  text-decoration: none;
  color: #fff;
  font-family: "Lora";
  text-transform: none;
  line-height: .7rem;
  transition: background-color 0.5s ease;
  font-size: 1.75rem;
}

.c-nav-bar__toggle .c-nav__link {
  line-height: 1rem;
}

@media (min-width: 62499.9375em) {
  .c-nav__link {
    padding-left: 0;
    text-align: center;
  }
}

.c-nav__item--current > .c-nav__link {
  color: #fff;
}

.c-nav__link:hover {
  text-decoration: none;
  background: transparent;
  color: #fff;
  background-color: #32383e;
}

.c-nav__link--active, .c-nav__link--open {
  text-decoration: none;
  background: transparent;
}

.app-nav--level-1 .c-nav__link:after {
  display: none;
}

@media (min-width: 62499.9375em) {
  .c-nav__link:after {
    display: none;
  }
}

.c-nav__dropdown .c-nav__link {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1.25rem;
}

.c-nav__link-text {
  flex: 1 0 calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  line-height: 1.5;
}

@media (min-width: 62499.9375em) {
  .c-nav__link-text {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.c-nav__link--action {
  flex: 0 0 3rem;
  max-width: 3rem;
  padding-left: .75rem;
  align-self: flex-end;
}

.c-nav__item--parent .c-nav__link--action {
  width: 2rem;
  padding-left: .25rem;
}

@media (min-width: 62499.9375em) {
  .c-nav__link--action {
    display: none;
  }
}

.c-nav__link--back-link {
  height: 3rem;
}

@media (max-width: 62499.9275em) {
  .c-nav__link--has-icon {
    display: flex;
  }
}

.c-nav__toggle {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  width: 1rem;
  height: 1rem;
  line-height: 1;
  border-radius: 50%;
}

.c-nav__toggle--close {
  border: 1px solid #212529;
}

@media (min-width: 62499.9375em) {
  .c-nav__toggle {
    display: none;
  }
}

.app-nav__link--back-link .c-nav__toggle {
  display: inline-block;
}

.c-nav-bar__toggle .c-nav__toggle {
  width: 2rem;
  height: 2rem;
}

.c-nav__item--has-dropdown .c-nav__toggle {
  transform: rotate(90deg);
}

.c-nav__dropdown {
  display: none;
  z-index: 1002;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #212529;
}

.c-nav__dropdown--open {
  display: block;
  height: auto;
  margin-bottom: 1rem;
}

.c-quick-links .c-nav__dropdown--open {
  margin-bottom: 0;
}

.c-nav-list {
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
  font-family: "Lora";
}

.c-nav-list__item {
  border-bottom: 1px solid #fff;
  background-color: #eeeeee;
}

.c-nav-list__link {
  display: block;
  width: 100%;
  padding: 0.5rem calc(0.5rem * 2);
  transition: none;
}

.c-nav-list__link--active {
  color: #fff;
  background-color: #999999;
}

.c-nav-list__link--active:hover {
  color: #fff;
  transition: none;
  text-decoration: none;
}

.c-nav-list--level-0 .c-nav-list__link {
  padding-left: calc(0.5rem * 2);
}

.c-nav-list--level-1 .c-nav-list__link {
  padding-left: calc(0.5rem * 3);
}

.c-nav-list--level-2 .c-nav-list__link {
  padding-left: calc(0.5rem * 4);
}

.c-quick-links {
  display: block;
  position: relative;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .c-quick-links {
    padding-left: 0;
  }
}

.c-quick-links__button {
  display: flex;
  flex-direction: row;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0;
}

@media (min-width: 62em) {
  .c-quick-links__button {
    width: 100%;
  }
}

.c-quick-links__text {
  align-self: flex-end;
  font-family: "Lora";
  font-size: 0.8rem;
  color: #212529;
  text-transform: uppercase;
}

@media (min-width: 62em) {
  .c-quick-links__text {
    font-size: 1.45rem;
  }
}

.c-quick-links__icon {
  margin-right: .25rem;
  font-size: 1.5rem;
  color: #212529;
  width: 1rem;
  height: 1.3rem;
}

@media (min-width: 62em) {
  .c-quick-links__icon {
    width: 1.3rem;
    height: 1.6rem;
  }
}

.c-language-menu {
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
  display: flex;
  position: relative;
  margin-bottom: 0;
}

@media (min-width: 62499.9375em) {
  .c-language-menu {
    padding-right: 0;
  }
}

.c-language-menu__item {
  flex: 1 0 auto;
  max-width: 100%;
  text-align: center;
}

.c-language-menu__link {
  display: inline-block;
  color: #212529;
  font-weight: bold;
}

.c-language-menu__link:hover {
  text-decoration: none;
}

.c-language-menu__link-text {
  display: inline-block;
  padding: .6rem;
}

.c-language-selection__item {
  display: flex;
  justify-content: space-between;
  padding: calc(1.5rem * 1.5 / 2);
  border: 0.125rem solid #999999;
  margin-bottom: 1rem;
  text-decoration: none;
}

.c-language-selection__item--secondary {
  margin-left: 5rem;
}

.c-language-selection__item:hover {
  border-color: #212529;
}

.c-language-selection__item-aside {
  flex: 0 0 4rem;
  max-width: 4rem;
  height: 3rem;
  border-right: 1px solid #999999;
  color: #999999;
}

.c-language-selection__item-actions {
  flex: 0 0 8rem;
  max-width: 16rem;
  text-align: right;
}

@media (min-width: 62499.9375em) {
  .c-language-selection__item-actions {
    flex: 0 0 16rem;
  }
}

.c-language-selection__item-main {
  flex: 1 0 auto;
  max-width: 80%;
  padding-left: calc(1.5rem * 1.5 / 2);
  padding-right: calc(1.5rem * 1.5 / 2);
}

.c-language-selection__item-main--centered {
  display: flex;
  align-items: center;
}

.c-language-selection__item-title {
  margin: 0;
  line-height: 1;
}

.app-pathbar {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(1.5rem * 1.5);
  padding-left: calc(1.5rem * 1.5);
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.template-frontpage-view .app-pathbar {
  display: none;
}

.template-language-root-view .app-pathbar {
  display: none;
}

.app-main--grid .app-pathbar {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 62em) {
  .app-pathbar {
    padding-right: 0;
    padding-left: 0;
  }
}

.c-breadcrumbs {
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
  display: flex;
  flex-wrap: wrap;
  padding: calc(1.5rem * 1.5 / 2) 0;
  margin-bottom: calc(1.5rem * 1.5);
  background-color: transparent;
}

.c-breadcrumbs__item {
  font-size: 1rem;
}

.c-breadcrumbs__item + .c-breadcrumbs__item {
  padding-left: 0.5rem;
}

.c-breadcrumbs__item + .c-breadcrumbs__item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #5a5f64;
  content: "/";
}

.c-banner--front-page {
  margin-top: 8.5rem;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.c-banner__content {
  text-align: center;
  background-color: #fff;
  background: url("../assets/images/static-banner.jpg") center no-repeat;
  background-size: cover;
  color: #fff;
  min-height: 400px;
}

@media (min-width: 48em) {
  .c-banner__content {
    min-height: 600px;
  }
}

.c-banner__header {
  position: absolute;
  bottom: 14%;
  left: 0;
  padding: 2rem;
  font-family: "Lora";
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: .5rem;
  line-height: 1rem;
}

@media (min-width: 48em) {
  .c-banner__header {
    font-size: 1.5rem;
  }
}

@media (min-width: 62em) {
  .c-banner__header {
    bottom: 14%;
    padding: 0 6rem;
    font-size: 2rem;
  }
}

.c-banner__description {
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4rem 2rem;
  font-size: 1rem;
}

@media (min-width: 62em) {
  .c-banner__description {
    padding: 0 6rem 4rem 6rem;
  }
}

.c-snippet {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1.5rem;
}

@media (min-width: 48em) {
  .c-snippet {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.c-snippet__aside {
  flex: 1 0 100%;
  padding-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 48em) {
  .c-snippet__aside {
    flex: 1 0 25%;
    max-width: 25%;
    padding-left: calc(1.5rem * 1.5);
    padding-top: 2.75rem;
  }
}

.c-snippet__main {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  padding-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 48em) {
  .c-snippet__main {
    flex: 1 0 100%;
    max-width: 100%;
    padding-bottom: calc(1.5rem / 2);
  }
  .c-snippet__main--grid-item {
    flex: 1 0 75%;
    max-width: 75%;
  }
}

.c-snippet__footer {
  font-size: 1rem;
  font-family: "Lora";
  padding-top: calc(1.5rem / 2);
}

.c-snippet__kicker {
  font-size: 1rem;
  font-family: "Lora";
  padding-left: 0.125rem;
  padding-bottom: calc(1.5rem / 2);
}

.c-snippet__title {
  font-weight: 300;
}

.c-snippet__section {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .c-snippet__section {
    font-size: 1.5rem;
  }
}

.c-page-department {
  font-family: "Lora";
  margin-bottom: 1rem;
}

.c-snippet__abstract {
  color: #5a5f64;
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .c-snippet__abstract {
    font-size: 1.5rem;
  }
}

.c-snippet__link {
  display: block;
  text-decoration: none;
}

.c-snippet__link--abstract {
  color: #5a5f64;
}

.c-snippet__link--abstract:hover {
  color: #5a5f64;
  text-decoration: none;
}

@media (min-width: 36em) and (max-width: 47.99em) {
  .c-snippet__figure--constrained-width-md, .c-snippet__figure--faculty {
    max-width: 50%;
  }
}

@keyframes toolBarEntrance {
  from {
    height: 0;
  }
  to {
    height: 3.5rem;
  }
}

.u-animation--grow {
  animation-name: toolBarEntrance;
}

.c-toolbar {
  display: block;
}

.c-toolbar--default {
  margin-bottom: 1.5rem;
}

.c-toolbar--primary {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  animation-name: toolBarEntrance;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.c-toolbar + .o-page__header {
  margin-top: 3.5rem;
}

.c-toolbar__content {
  display: flex;
  padding: 0.5rem calc(1.5rem * 1.5 / 2);
  color: #d9d9d9;
  background-color: #212529;
}

.c-toolbar__item {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.c-toolbar__title {
  text-align: center;
  font-family: "Lora";
}

.c-toolbar__actions {
  width: 100%;
  text-align: right;
}

.c-toolbar__action {
  color: #d9d9d9;
}

.app-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 60px;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.c-panels {
  display: block;
  width: 100%;
  overflow: visible;
}

.c-panel {
  position: relative;
  padding: 0 0 0 0;
}

.c-panels--header .c-panel--1 {
  padding-top: 0;
}

@media (min-width: 48em) {
  .c-panels--header .c-panel--1 {
    padding-top: 1rem;
  }
}

.c-panel--full-width {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

.c-panel--centered {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.c-panel--primary {
  color: #212529;
  background-color: whitesmoke;
}

.c-panel--secondary {
  background-color: whitesmoke;
}

.c-panel--disabled {
  opacity: .5;
}

.c-panel__header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 1;
  transition: opacity .25s ease-in-out;
}

.c-panels--view .c-panel__header {
  display: none;
}

.c-panel__main {
  position: relative;
  width: 100%;
  padding-top: calc(1.5rem * 1.5);
  padding-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 768px) {
  .c-panel__main {
    padding-bottom: 0;
  }
}

.c-panel__main--container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.c-panel__main--centered {
  margin-left: auto;
  margin-right: auto;
}

.c-panel--1 .c-panel__main {
  padding-top: 0;
}

.c-panel--full-width .c-panel__main {
  padding-right: calc(1.5rem * 1.5);
  padding-left: calc(1.5rem * 1.5);
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 62em) {
  .c-panel--full-width .c-panel__main {
    padding-right: 0;
    padding-left: 0;
  }
}

.c-panel--editable .c-panel__main {
  min-height: 2rem;
}

.c-panels--view .c-panel__footer {
  display: none;
}

.c-panel__icon {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  z-index: 11;
  border: 0.125rem solid #212529;
  border-radius: 50%;
  color: #fff !important;
  background-color: #212529;
  font-size: 1rem;
  font-family: "Lora";
}

.c-panel__icon:hover {
  color: #eeeeee;
}

.c-panel__header:hover .c-panel__icon {
  display: flex;
}

.c-panel__icon--fixed-bottom {
  position: absolute;
  bottom: 0;
}

.c-panel__controls {
  position: relative;
  left: calc(100% - 2rem);
  display: flex;
  width: 2rem;
  height: 0;
  color: #fff;
  background-color: #212529;
  transition: height .25s ease;
}

.c-panel__controls::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 0.2rem / 2);
  overflow: hidden;
  width: 0.2rem;
  height: 0;
  background-color: #212529;
  transition: height .25s ease;
}

.c-panel__header:hover .c-panel__controls {
  height: 100%;
}

.c-panel__header:hover .c-panel__controls::before {
  height: 100%;
}

.c-panel-factory {
  position: relative;
  left: 0;
  opacity: 0;
  transition: opacity .25s ease-in-out;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0.25rem 0;
}

.c-panel-factory::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 0.2rem / 2);
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0.2rem;
  background-color: #212529;
  transition: width .25s ease;
}

.c-panel-factory:hover {
  opacity: 1;
}

.c-panel-factory:hover::before {
  width: 100%;
}

.c-panels--view .c-panel-factory {
  display: none;
}

.c-panel-factory__action {
  position: relative;
  left: calc(0 - 2rem / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 0.125rem solid #212529;
  border-radius: 50%;
  color: #fff;
  background-color: #212529;
  font-size: 1rem;
  font-family: "Lora";
}

.c-panel-factory__action:hover {
  color: #eeeeee;
}

.c-panel-editor {
  position: relative;
}

.c-panel-editor--centered {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.c-panel-editor__header {
  height: 4rem;
  min-height: 4rem;
}

.c-panel-editor__section-headline {
  padding: .5rem;
  margin-bottom: 1rem;
  border-bottom: 0.0125rem solid #212529;
}

.c-panel-editor__section-headline {
  font-family: "Lora";
  text-transform: uppercase;
}

.c-panel-editor__footer {
  padding: calc(1.5rem * 1.5) 0;
}

.c-panel-overlay {
  background-color: #fff;
  overflow: scroll;
}

.c-panel-overlay--active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1001;
  width: 100%;
}

.c-panel-overlay__content {
  padding: calc(1.5rem * 1.5) calc(calc(1.5rem * 1.5) * 1.5) 0;
  text-align: right;
}

.c-tiles {
  display: block;
  width: 100%;
  overflow: visible;
  padding-top: calc(1.5rem * 1.5);
  padding-bottom: calc(1.5rem * 1.5);
}

.c-tiles__content {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.c-tiles__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-tiles__list-item {
  flex: 1 0 100%;
  padding-right: calc(1.5rem * 1.5 / 2);
  padding-left: calc(1.5rem * 1.5 / 2);
}

@media (min-width: 48em) {
  .c-tiles__list-item {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

.c-tile__header {
  display: flex;
  margin-bottom: calc(1.5rem * 1.5);
  padding-top: calc(1.5rem * 1.5 / 2);
  padding-bottom: calc(1.5rem * 1.5 / 2);
  border: 0 solid #fff;
  border-top-width: .125rem;
  border-bottom-width: .125rem;
}

.c-tile__title {
  flex: 1 0 auto;
  font-family: "Lora";
  font-size: 2.625rem;
}

.c-tile__symbol {
  display: flex;
  align-items: center;
  flex: 1 0 2.5rem;
  max-width: 2.5rem;
}

.c-tile__main {
  padding-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 48em) {
  .c-tile__main {
    padding-right: calc(1.5rem * 1.5);
  }
}

.c-card {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: calc(1.5rem * 1.5);
}

.c-list__item .c-card {
  padding-bottom: 0;
}

.c-card--spacer {
  padding: calc(1.5rem * 1.5 / 2);
}

.c-card--page-section {
  display: block;
  height: auto;
}

@media (min-width: 62em) {
  .c-card--page-section {
    margin-left: calc(1.5rem * 1.5);
  }
}

.c-card--nav {
  justify-content: flex-start;
}

.c-card__header {
  font-family: "Montserrat";
  font-size: 1.875rem;
  font-weight: 300;
  padding: calc(1.5rem * 1.5 / 2);
  background: #fff;
}

.c-card--default .c-card__header {
  flex: 1 0 auto;
  height: 8rem;
  text-align: center;
}

.c-card--page-section .c-card__header {
  background: #eeeeee;
  font-size: 1.5rem;
  line-height: 1.25;
}

.c-card--selector .c-card__header {
  background: #eeeeee;
}

.c-card__main {
  font-family: "Lora";
  background-color: #fff;
  height: 100%;
}

.c-card--page-section .c-card__main {
  display: block;
  font-size: 1rem;
}

@media (min-width: 62em) {
  .c-card--nav .c-card__main {
    max-height: 1500px;
    display: block;
  }
}

.c-card__footer {
  margin-top: auto;
  font-family: "Montserrat";
  color: #fff;
  background: #5a5f64;
  font-size: 1rem;
}

.c-card--news .c-card__footer {
  text-align: left;
}

.c-card--default .c-card__footer {
  text-align: center;
  padding: calc(1.5rem * 1.5 / 2);
}

.c-card__footer--centered {
  text-align: center;
}

.c-card__footer--action {
  display: inline-block;
  margin-top: .7rem;
  padding: 0;
  width: 1.1rem;
  height: 1.1rem;
  border: 1px solid #212529;
  line-height: 1;
  border-radius: 50%;
}

.c-card__footer--link {
  display: inline-block;
  padding: 0 0 0 .5rem;
  margin-top: .5rem;
  color: #fff !important;
}

.c-card__link {
  display: block;
  width: 100%;
  color: #212529;
  text-decoration: none;
}

.c-card__link:hover {
  color: #0a0c0d;
  text-decoration: none;
}

.c-card__link--header {
  text-align: center;
}

.c-card__link--main {
  color: #212529;
  text-align: center;
}

.c-card__date {
  font-weight: 100;
  font-size: calc(1.275rem + 0.3vw);
  color: #212529;
}

@media (min-width: 1200px) {
  .c-card__date {
    font-size: 1.5rem;
  }
}

.c-card__title {
  font-family: "Montserrat";
  color: #212529;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  text-transform: uppercase;
}

.c-card__title-text {
  align-self: center;
}

.c-card__section {
  padding: calc(1.5rem * 1.5 / 2);
}

.c-card__section--centered {
  text-align: center;
}

.c-card__detail {
  font-family: "Montserrat";
}

.c-toc {
  font-family: "Lora";
  position: relative;
  font-size: calc(1.275rem + 0.3vw);
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

@media (min-width: 1200px) {
  .c-toc {
    font-size: 1.5rem;
  }
}

@media (min-width: 75em) {
  .c-toc {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.c-toc__item {
  display: none;
  padding: .35rem 0 .35rem 0;
  text-transform: none;
  line-height: 1.5;
}

.c-toc__item--has-subtree {
  display: flex;
  border-right: 1px solid #fff;
  flex-wrap: wrap;
}

@media (min-width: 75em) {
  .c-toc__item--has-subtree {
    flex-direction: column;
  }
}

.c-toc__item.en {
  display: none;
}

.c-toc--level-1 .c-toc__item {
  display: flex;
  text-transform: none;
  margin-bottom: 0;
  border-right: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 75em) {
  .c-toc--level-1 .c-toc__item {
    flex-direction: column;
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 75em) {
  .c-toc__item {
    padding: .3rem 1.5rem;
    flex: 1 0 16.6666%;
    max-width: 16.6666%;
    margin-bottom: 0;
  }
  .c-toc__item:first-of-type {
    padding-left: 0;
  }
}

.c-toc__item--has-subtree {
  border: 0px solid rgba(255, 255, 255, 0.1);
}

@media (min-width: 75em) {
  .c-toc__item--has-subtree:nth-child(even) {
    border-left-width: 2px;
  }
}

@media (min-width: 75em) {
  .c-toc__item--has-subtree:not(:first-child) {
    border-left-width: 2px;
  }
}

.c-toc--level-1 {
  display: none;
  text-transform: none;
}

@media (max-width: 74.99em) {
  .c-toc--level-1 {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.show .c-toc--level-1 {
  display: block;
}

@media (min-width: 75em) {
  .c-toc--level-1 {
    display: block;
  }
}

.c-toc__link {
  display: flex;
  width: 100%;
  min-height: calc(1.5rem * 1.5);
  line-height: 1.25;
  color: #fff;
  font-size: 1.5rem;
}

@media (min-width: 75em) {
  .c-toc__link {
    display: block;
    height: calc(1.5rem * 2.5);
    text-decoration: none;
    cursor: pointer;
  }
}

.c-toc--level-1 .c-toc__link {
  display: block;
  height: auto;
  font-size: 1rem;
}

.c-toc__link:hover {
  color: #fff;
}

.c-toc__link--toggle {
  text-decoration: none;
  cursor: default;
}

.c-toc__link--toggle:hover {
  text-decoration: none;
}

.c-toc__link-item {
  flex: 1 0 50%;
  max-width: 50%;
  line-height: 1.2rem;
}

.c-toc__link-item--icon {
  text-align: right;
  color: #fff;
}

@media (min-width: 75em) {
  .c-toc__link-item--icon {
    display: none;
  }
}

.c-toc__toggle {
  display: block;
  flex: 1 0 50%;
  color: #fff;
  text-align: right;
}

@media (min-width: 75em) {
  .c-toc__toggle {
    display: none;
  }
}

.c-toc__icon {
  color: currentColor;
}

.c-toc__icon--close {
  display: none;
}

.show .c-toc__icon--open {
  display: none;
}

.show .c-toc__icon--close {
  display: inline-block;
}

.c-social-menu {
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
}

.c-social-menu--primary {
  margin: 0 auto 1rem auto;
}

.c-social-menu__item {
  display: none;
  flex: 1 0 auto;
  max-width: 100%;
  text-align: center;
}

.c-social-menu__item:not(:first-child) {
  margin-left: calc(1.5rem * 1.5 / 2);
}

@media (min-width: 62499.9375em) {
  .c-social-menu__item--default {
    display: block;
  }
}

.c-social-menu__item--primary {
  display: block;
}

.c-social-menu__link {
  display: inline-block;
  color: #212529;
}

.c-social-menu__link:hover {
  text-decoration: none;
}

.c-social-menu__link-text {
  display: none;
}

@media (min-width: 62499.9375em) {
  .c-social-menu__link-text {
    display: inline-block;
  }
}

.c-social-menu__link--button {
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 0;
}

.c-social-bar {
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: calc(1.5rem * 1.5 / 2);
}

.c-social-bar__list {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 0;
}

.c-social-bar__item {
  flex: 1 0 25%;
  max-width: 25%;
  text-align: left;
  border: 1px solid #fff;
}

.c-social-bar__button {
  width: 100%;
  padding: 0;
  color: #000;
}

.c-social-bar__button:hover {
  color: #212529 !important;
  background: #5a5f64 !important;
}

.c-social-bar__button:visited {
  color: #212529 !important;
}

.c-social-bar__text {
  display: none;
}

.c-social-bar__button--facebook {
  color: #5a5f64;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--facebook:hover {
  color: #fff;
  background-color: #5a5f64;
  border-color: #f6f6f6;
}

.btn-check:focus + .c-social-bar__button--facebook, .c-social-bar__button--facebook:focus {
  color: #fff;
  background-color: #5a5f64;
  border-color: #f6f6f6;
  box-shadow: 0 0 0 0.25rem rgba(222, 223, 223, 0.5);
}

.btn-check:checked + .c-social-bar__button--facebook,
.btn-check:active + .c-social-bar__button--facebook, .c-social-bar__button--facebook:active, .c-social-bar__button--facebook.active,
.show > .c-social-bar__button--facebook.dropdown-toggle {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}

.btn-check:checked + .c-social-bar__button--facebook:focus,
.btn-check:active + .c-social-bar__button--facebook:focus, .c-social-bar__button--facebook:active:focus, .c-social-bar__button--facebook.active:focus,
.show > .c-social-bar__button--facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 223, 223, 0.5);
}

.c-social-bar__button--facebook:disabled, .c-social-bar__button--facebook.disabled {
  color: #000;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--twitter {
  color: #5a5f64;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--twitter:hover {
  color: #fff;
  background-color: #5a5f64;
  border-color: #f6f6f6;
}

.btn-check:focus + .c-social-bar__button--twitter, .c-social-bar__button--twitter:focus {
  color: #fff;
  background-color: #5a5f64;
  border-color: #f6f6f6;
  box-shadow: 0 0 0 0.25rem rgba(222, 223, 223, 0.5);
}

.btn-check:checked + .c-social-bar__button--twitter,
.btn-check:active + .c-social-bar__button--twitter, .c-social-bar__button--twitter:active, .c-social-bar__button--twitter.active,
.show > .c-social-bar__button--twitter.dropdown-toggle {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}

.btn-check:checked + .c-social-bar__button--twitter:focus,
.btn-check:active + .c-social-bar__button--twitter:focus, .c-social-bar__button--twitter:active:focus, .c-social-bar__button--twitter.active:focus,
.show > .c-social-bar__button--twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 223, 223, 0.5);
}

.c-social-bar__button--twitter:disabled, .c-social-bar__button--twitter.disabled {
  color: #000;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--xing {
  color: #5a5f64;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--xing:hover {
  color: #fff;
  background-color: #5a5f64;
  border-color: #f6f6f6;
}

.btn-check:focus + .c-social-bar__button--xing, .c-social-bar__button--xing:focus {
  color: #fff;
  background-color: #5a5f64;
  border-color: #f6f6f6;
  box-shadow: 0 0 0 0.25rem rgba(222, 223, 223, 0.5);
}

.btn-check:checked + .c-social-bar__button--xing,
.btn-check:active + .c-social-bar__button--xing, .c-social-bar__button--xing:active, .c-social-bar__button--xing.active,
.show > .c-social-bar__button--xing.dropdown-toggle {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}

.btn-check:checked + .c-social-bar__button--xing:focus,
.btn-check:active + .c-social-bar__button--xing:focus, .c-social-bar__button--xing:active:focus, .c-social-bar__button--xing.active:focus,
.show > .c-social-bar__button--xing.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 223, 223, 0.5);
}

.c-social-bar__button--xing:disabled, .c-social-bar__button--xing.disabled {
  color: #000;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--linkedin {
  color: #5a5f64;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-social-bar__button--linkedin:hover {
  color: #fff;
  background-color: #5a5f64;
  border-color: #f6f6f6;
}

.btn-check:focus + .c-social-bar__button--linkedin, .c-social-bar__button--linkedin:focus {
  color: #fff;
  background-color: #5a5f64;
  border-color: #f6f6f6;
  box-shadow: 0 0 0 0.25rem rgba(222, 223, 223, 0.5);
}

.btn-check:checked + .c-social-bar__button--linkedin,
.btn-check:active + .c-social-bar__button--linkedin, .c-social-bar__button--linkedin:active, .c-social-bar__button--linkedin.active,
.show > .c-social-bar__button--linkedin.dropdown-toggle {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f6f6f6;
}

.btn-check:checked + .c-social-bar__button--linkedin:focus,
.btn-check:active + .c-social-bar__button--linkedin:focus, .c-social-bar__button--linkedin:active:focus, .c-social-bar__button--linkedin.active:focus,
.show > .c-social-bar__button--linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 223, 223, 0.5);
}

.c-social-bar__button--linkedin:disabled, .c-social-bar__button--linkedin.disabled {
  color: #000;
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.c-meta-nav {
  display: block;
  padding-left: .25rem;
  padding-right: .25rem;
  text-align: center;
}

@media (min-width: 48em) {
  .c-meta-nav {
    padding-left: 0;
    text-align: left;
  }
}

.c-meta-nav__item {
  display: inline-block;
}

.c-meta-nav__item:not(:last-child):after {
  content: '\00B7';
  display: inline-block;
  margin-left: .25rem;
  margin-right: .25rem;
}

.c-meta-nav__link {
  color: #212529;
  font-family: "Lora";
  font-size: 1rem;
}

.c-meta-nav__link:hover {
  color: black;
}

.c-interferer--front-page {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  background: url("../assets/images/static-interferer.jpg") center no-repeat;
  background-size: cover;
  min-height: 400px;
}

@media (min-width: 48em) {
  .c-interferer--front-page {
    min-height: 530px;
  }
}

.c-interferer__content {
  padding: 3rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 48em) {
  .c-interferer__content {
    padding: 6rem;
  }
}

.c-interferer__header {
  font-family: "Lora";
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: left;
}

@media (min-width: 48em) {
  .c-interferer__header {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 62em) {
  .c-interferer__header {
    font-size: 2rem;
  }
}

.c-interferer__description {
  font-size: 1rem;
  line-height: 1rem;
}

@media (min-width: 62em) {
  .c-interferer__description {
    padding: 0;
  }
}

.c-list {
  display: block;
  margin-left: 0;
  padding-left: 0;
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
}

.c-list--grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-list--baseline {
  align-content: baseline;
}

.c-list--gutter {
  width: 100%;
  margin: auto;
}

.c-list--tiles {
  margin-bottom: calc(1.5rem * 1.5);
}

.c-list__item {
  margin-bottom: calc(calc(1.5rem * 1.5 * 1.25) * 1.5);
}

.c-list--grid .c-list__item {
  flex: 1 0 auto;
  max-width: 100%;
}

@media (min-width: 62em) {
  .c-list--grid .c-list__item {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 62em) {
  .c-list--gutter .c-list__item {
    padding-right: calc(1.5rem * 1.5 * 1.25);
  }
  .c-list--gutter .c-list__item:not(:first-of-type) {
    padding-left: calc(1.5rem * 1.5 * 1.25);
  }
}

c-list--bordered .c-list__item {
  border-bottom: 1px solid #212529;
}

c-list--bordered .c-list__item:last-of-type {
  border-bottom: 0px;
}

.c-list__item--card {
  position: relative;
}

.c-list__item--action {
  color: #4e5862;
}

.c-list__item--action:hover {
  text-decoration: none;
  background-color: #eeeeee;
  color: #212529;
}

.c-card-list .c-card-list__item {
  padding-right: calc(1.5rem * 1.5);
}

.c-card-list .c-card-list__item:not(:first-of-type) {
  padding-left: calc(1.5rem * 1.5);
}

@media (min-width: 48em) {
  .c-card-list .c-card-list__item:not(last) {
    border-right: 1px solid #5a5f64;
  }
}

.c-card-list .c-card-list__item:last-of-type {
  border-right: 0;
}

.c-filter__section {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.c-filter__block {
  flex: 1 0 0%;
}

.c-filter__block--action {
  text-align: right;
}

.c-filter__block--container {
  border: 1px solid #f8f9fa;
}

.c-filter__block--hidden {
  display: none;
}

.c-filter__items {
  width: 100%;
  display: block;
}

@media (min-width: 48em) {
  .c-filter__items {
    display: flex;
    flex-direction: row;
  }
}

.c-filter__item {
  flex: 1 0 0%;
  margin-bottom: .5rem;
}

@media (min-width: 48em) {
  .c-filter__item {
    flex: 1 0 25%;
    padding-right: .5rem;
  }
}

.c-filter__item:last-child {
  padding-right: 0;
}

.c-filter-item {
  width: 100%;
}

.c-filter-item__header {
  padding: .5rem 1rem;
  background: #eeeeee;
  font-weight: 700;
  font-family: "Montserrat";
  margin-bottom: .5rem;
}

.c-filter-list__result-count {
  margin-bottom: 1rem;
  font-weight: 700;
  font-family: "Montserrat";
}

.c-filter-list__result-index {
  background: #eeeeee;
  padding: 1rem;
  margin-top: 1rem;
}

.c-collapsible__header {
  display: flex;
  width: 100%;
  background: whitesmoke;
  padding: 0.5rem 1rem;
  border-bottom: 0.125rem solid #5a5f64;
}

@media (max-width: 74.99em) {
  .c-collapsible__header {
    cursor: pointer;
    border-bottom: 0.125rem solid #5a5f64;
  }
}

@media (min-width: 62em) {
  .c-collapsible__header--hidden-lg {
    display: none;
  }
}

@media (min-width: 75em) {
  .c-collapsible__header--hidden-xl {
    display: none;
  }
}

.c-collapsible__item {
  max-height: 0;
  overflow: hidden;
  transition: max-height .75s ease-in-out;
}

@media (min-width: 48em) {
  .c-collapsible__item--md {
    max-height: 1500px;
  }
}

@media (min-width: 62em) {
  .c-collapsible__item--lg {
    max-height: 1500px;
  }
}

.c-collapsible__item--active {
  display: block;
  max-height: 1500px;
}

@media (min-width: 75em) {
  .c-collapsible__item--active {
    max-height: 1500px;
  }
}

.c-collapsible__link {
  flex: 1 0 50%;
  max-width: 50%;
  margin-bottom: 0;
  font-family: "Lora";
}

@media (min-width: 62em) {
  .c-card--nav .c-collapsible__link {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.c-collapsible__link--icon {
  text-align: right;
  color: currentColor;
}

@media (min-width: 75em) {
  .c-collapsible__link--icon {
    display: none;
  }
}

@media (min-width: 62em) {
  .c-card--nav .c-collapsible__link--icon {
    display: none;
  }
}

@media (min-width: 75em) {
  .c-collapsible__link {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.c-collapsible__icon {
  color: currentColor;
}

.c-collapsible__icon--close {
  display: none;
}

.show .c-collapsible__icon--open {
  display: none;
}

.show .c-collapsible__icon--close {
  display: inline-block;
}

.c-teaser {
  width: 100%;
}

.c-teaser__header {
  margin-bottom: calc(1.5rem * 1.5);
  color: #000;
}

.c-teaser__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-teaser__footer {
  text-align: right;
  font-family: "Lora";
  padding-bottom: 1rem;
}

@media (min-width: 62em) {
  .c-teaser__footer {
    padding-bottom: 0;
  }
}

.c-teaser__link {
  text-decoration: none;
}

.c-teaser__link:hover {
  text-decoration: none;
}

.c-event-info__title {
  margin-bottom: 0;
  font-family: "Lora";
}

.c-link-tile {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #5a5f64;
  padding: calc(1.5rem * 1.5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (min-width: 62em) {
  .c-link-tile {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.c-link-tile:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.c-link-tile__aside {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 62em) {
  .c-link-tile__aside {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-link-tile__main {
  width: 100%;
}

@media (min-width: 62em) {
  .c-link-tile__main {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.c-link-tile__footer {
  width: 100%;
  color: #fff;
  background: #5a5f64;
}

@media (min-width: 62em) {
  .c-link-tile__footer {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.c-link-tile__asset {
  display: block;
}

.c-link-tile__icon {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 48em) {
  .c-link-tile__icon {
    width: 5rem;
    height: 5rem;
  }
}

.c-link-tile__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: calc(1.5rem * 1.5);
  margin-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 48em) {
  .c-link-tile__header {
    text-align: center;
  }
}

.c-link-tile__title {
  font-size: 2.625rem;
  color: #5a5f64;
}

.c-link-tile__abstract {
  margin-bottom: calc(1.5rem * 1.5);
}

.c-link-tile__body {
  font-weight: 300;
}

.c-link-tile__link {
  display: block;
  width: 100%;
  color: #212529;
}

.c-link-tile__link:hover {
  text-decoration: none;
  color: black;
}

.c-link-tile__link--primary {
  color: #5a5f64;
}

.c-link-tile__link--primary:hover {
  color: #36393c;
}

@media (min-width: 48em) {
  .c-link-tile__link {
    padding: calc(1.5rem * 1.5);
  }
}

.c-poster {
  display: block;
  position: relative;
  width: 100%;
}

.c-poster__main {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  color: #fff;
}

@media (min-width: 48em) {
  .c-poster__main {
    padding: 3rem;
  }
}

.c-poster__header {
  font-family: "Lora";
  font-size: 2.25rem;
  line-height: 1.5;
}

@media (min-width: 48em) {
  .c-poster__header {
    line-height: 1.5;
  }
}

.c-poster__description {
  font-size: 1.5rem;
  line-height: 1.5;
}

.c-poster__link {
  color: #fff;
  text-decoration: none;
}

.c-poster__link:hover {
  color: #fff;
}

@media (max-width: 47.99em) {
  .c-poster__aside .o-figure__card::before {
    padding-bottom: 100%;
  }
}

.c-poster__text a {
  color: #fff;
}

.c-poster__text a:hover {
  color: #fff;
}

.c-slider {
  display: block;
  width: 100vw;
  margin-left: -calc(1.5rem * 1.5);
  margin-right: -calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .c-slider {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.c-slider__main {
  width: 100%;
}

.c-slider__items {
  display: block;
  position: relative;
  width: 100%;
}

.c-slide {
  display: block;
  position: relative;
  width: 100%;
}

.c-slide__main {
  position: relative;
  padding: 2rem 2rem .5rem 2rem;
  color: #212529;
  width: 100%;
  background: #fff;
}

@media (min-width: 48em) {
  .c-slide__main {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.45));
    padding: 3rem 5rem;
  }
}

.c-slide__content a {
  color: #212529;
}

.c-slide__content a:hover {
  color: #212529;
}

.c-slide__content a:visited {
  color: #212529;
}

@media (min-width: 48em) {
  .c-slide__content {
    padding-bottom: 0;
  }
  .c-slide__content a {
    color: #fff;
  }
  .c-slide__content a:hover {
    color: #fff;
  }
  .c-slide__content a:visited {
    color: #fff;
  }
}

.c-slider__items {
  margin-bottom: 0;
}

.c-slide__figure {
  margin-bottom: 0;
}

@media (max-width: 35.99em) {
  .c-slide__figure .o-figure__card::before {
    padding-bottom: 100%;
  }
}

/* ---- flickity overrides ---- */
.flickity-prev-next-button {
  background: transparent;
}

@media (min-width: 62em) {
  .flickity-prev-next-button {
    width: 100px;
    height: 100px;
  }
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: -200%;
  width: 60%;
  height: 60%;
}

@media (min-width: 48em) {
  .flickity-prev-next-button .flickity-button-icon {
    top: 20%;
  }
}

.flickity-prev-next-button .arrow {
  fill: #fff;
}

.flickity-prev-next-button:hover {
  background: none;
}

.flickity-page-dots {
  display: block;
  text-align: center;
  position: absolute;
  top: 55%;
  padding-top: 0;
  max-height: 30px;
}

@media (min-width: 48em) {
  .flickity-page-dots {
    border: 0;
    top: 85%;
    bottom: 2rem;
    padding-top: 1rem;
  }
}

@media (min-width: 75em) {
  .flickity-page-dots {
    top: 90%;
  }
}

.flickity-page-dots .dot {
  background-color: #fff;
}

@media (min-width: 48em) {
  .flickity-page-dots .dot {
    background-color: #fff;
  }
}

.c-pane {
  margin: 0;
}

.c-pane__header {
  width: 100%;
  cursor: pointer;
}

.c-pane__title {
  display: flex;
  width: 100%;
  padding: calc(1.5rem * 1.5 / 2);
  margin-bottom: 0;
  border-bottom: 1px solid #eeeeee;
  background-color: whitesmoke;
}

.c-pane__headline {
  flex: 0 1 calc(100% - 4rem);
}

.c-pane__toggle {
  flex: 0 1 4rem;
  text-align: right;
}

.c-pane__main {
  display: none;
  transition: ease .3s all;
  animation-name: fadeIn;
  animation-duration: .6s;
  animation-fill-mode: both;
  will-change: transform;
}

.c-pane__main--active, .c-pane__main.c-collapsible__item--active {
  display: block;
}

.c-pane__content {
  display: block;
  padding: calc(1.5rem * 1.5) calc(1.5rem * 1.5 / 2);
}

.c-pane__icon {
  color: currentColor;
}

.c-pane__icon--close {
  display: none;
}

.show .c-pane__icon--open {
  display: none;
}

.show .c-pane__icon--close {
  display: inline-block;
}

.c-colophon {
  display: block;
  position: relative;
  width: 100%;
  padding: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .c-colophon::before {
    content: "";
    display: block;
    width: 135px;
    height: 80px;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 65px);
    background: url("/assets/images/brand-globe.png") no-repeat 50% 0 transparent;
  }
}

.c-colophon__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.c-colophon__item {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 62em) {
  .c-colophon__item {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

.c-colophon__item--meta {
  padding-right: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .c-colophon__item--meta {
    padding-right: 6.5rem;
    text-align: right;
    justify-content: flex-end;
  }
}

.c-colophon__item--copyright {
  padding-left: calc(1.5rem * 1.5);
}

@media (min-width: 62em) {
  .c-colophon__item--copyright {
    padding-left: 6.5rem;
  }
}

.c-colophon__link {
  font-weight: 500;
  color: #5a5f64;
}

.c-colophon__text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
}

.app-action-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 6rem;
  background-color: #fff;
  border-top: 0.125rem solid #212529;
}

@media (min-width: 62499.9375em) {
  .app-action-bar {
    display: block;
  }
}

@media (min-width: 62499.9375em) {
  .app-action-bar--offset {
    display: none;
  }
}

.app-action-bar--offset {
  bottom: 0;
}

.c-action-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
}

.c-action-bar__item {
  flex: 1 0 auto;
}

.c-action-bar__button {
  text-align: center;
}

@media (min-width: 62499.9375em) {
  .c-action-bar__button {
    position: fixed;
    top: .25rem;
    right: calc(1.5rem * 1.5 * 1.25);
  }
}

.c-collage {
  display: block;
  width: 100%;
}

.c-collage__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -calc(1.5rem * 1.5);
}

@supports (grid-area: auto) {
  .c-collage__items {
    display: grid;
    grid-gap: calc(1.5rem * 1.5) calc(1.5rem * 1.5);
  }
}

.c-collage__items > * {
  margin-bottom: calc(1.5rem * 1.5);
  margin-left: calc(1.5rem * 1.5);
}

@media (min-width: 48em) {
  .c-collage__items > * {
    width: calc((99% / 2) - calc(1.5rem * 1.5));
    flex: 0 0 calc((99% / 2) - calc(1.5rem * 1.5));
  }
}

@media (min-width: 62em) {
  .c-collage__items > * {
    width: calc((99% / 4) - calc(1.5rem * 1.5));
    flex: 0 0 calc((99% / 4) - calc(1.5rem * 1.5));
  }
}

@supports (grid-area: auto) {
  .c-collage__items {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-left: 0;
  }
  .c-collage__items > * {
    width: auto;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.c-collage__item {
  background-color: #fff;
  padding: calc(1.5rem * 1.5 / 2) calc(1.5rem * 1.5 / 2) calc(1.5rem * 1.5);
}

.c-collage__items--1 .c-collage__item {
  width: 33.3333%;
  margin-left: auto;
  margin-right: auto;
}

.c-collage__item-figure {
  border: 1px solid whitesmoke;
}

.c-page-header {
  margin: 0 auto 1.5rem;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.c-page-header__title {
  margin-bottom: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .c-page-header__title {
    margin-bottom: 1.5rem;
  }
}

.c-page-header__headline {
  padding: .125rem 0;
  color: #212529;
  line-height: 1.25;
}

.c-page-header__headline--primary {
  color: #212529;
}

.c-page-header__abstract {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 1200px) {
  .c-page-header__abstract {
    font-size: 2rem;
  }
}

.c-page-header__meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-bottom: calc(1.5rem * 1.5);
  font-size: 1.25rem;
  color: #5a5f64;
}

.c-page-header__meta > * {
  padding-right: calc(1.5rem * 1.5 / 2);
}

.c-page-header__meta > *:first-child {
  flex: 0 0 100%;
}

.c-widget--hph-content-alias {
  margin-top: -calc(1.5rem * 1.5);
  margin-bottom: -calc(1.5rem * 1.5);
}

.c-widget {
  display: block;
}

.c-widget--card {
  width: 100%;
}

.c-widget__header {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: #fff;
  background-color: #000;
}

.c-widget__content--header {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  font-family: "Lora";
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  .c-widget__content--header {
    font-size: 2rem;
  }
}

.c-widget__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  margin-bottom: calc(1.5rem * 1.5 / 2);
}

.c-widget__icon--centered {
  margin-left: auto;
  margin-right: auto;
}

.c-widget__icon--default {
  width: 4rem;
  height: 4rem;
}

.c-widget__editor {
  display: block;
}

.c-widget__editor:hover {
  cursor: text;
}

.ph-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 -15px 0;
  overflow: hidden;
  margin-bottom: 0;
  background-color: #fff;
  border: 1px solid #e6e5e5;
  border-radius: 0;
}

.ph-item, .ph-item *,
.ph-item ::after, .ph-item ::before {
  box-sizing: border-box;
}

.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}

.ph-item > * {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding-right: 0;
  padding-left: 0;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}

.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #ced4da;
}

.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}

.ph-row .empty {
  background-color: rgba(255, 255, 255, 0);
}

.ph-col-2 {
  flex: 0 0 16.6666666667%;
}

.ph-col-4 {
  flex: 0 0 33.3333333333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.6666666667%;
}

.ph-col-10 {
  flex: 0 0 83.3333333333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #ced4da;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.ph-avatar::before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  color: #fff;
  background-color: #ced4da;
  margin-bottom: 15px;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.c-widget-selector {
  display: block;
}

.c-widget-selector__section {
  margin-bottom: 1;
}

.c-widget-selector__header {
  padding: .5rem;
  margin-bottom: 1rem;
  border-bottom: 0.0125rem solid #212529;
}

.c-widget-selector__headline {
  font-family: "Montserrat";
  text-transform: uppercase;
}

.c-widget-selector__item {
  display: block;
  opacity: .8;
  padding: calc(1.5rem * 1.5 / 2);
  color: #212529;
  border: .125rem solid transparent;
  /* <- here */
  transition: border-color 1s ease;
}

.c-widget-selector__item:hover {
  opacity: 1;
  border: 0.125rem solid #5a6570;
  text-decoration: none;
}

.c-widget-selector__item--selected {
  opacity: 1;
  border: 0.125rem solid #212529;
}

.c-widget-editor__item {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: calc(1.5rem * 1.5 / 2);
  border: 0.125rem solid #999999;
  margin-bottom: 1rem;
}

.c-widget-editor__item--secondary {
  margin-left: 5rem;
}

.c-widget-editor__item-aside {
  display: flex;
  align-items: center;
  flex: 0 0 4rem;
  max-width: 4rem;
  height: 3rem;
  border-right: 1px solid #999999;
  color: #999999;
}

.c-widget-editor__item-aside--stretch {
  height: auto;
}

.c-widget-editor__item-actions {
  flex: 0 0 16rem;
  max-width: 16rem;
  text-align: right;
}

.c-widget-editor__item-actions--sm {
  flex: 0 0 4rem;
  max-width: 4rem;
}

.c-widget-editor__item-main {
  flex: 1 0 auto;
  max-width: 80%;
  padding-left: calc(1.5rem * 1.5 / 2);
  padding-right: calc(1.5rem * 1.5 / 2);
}

.c-widget-editor__item-main--centered {
  display: flex;
  align-items: center;
}

.c-widget-editor__item-title {
  margin: 0;
  line-height: 1;
}

.c-widget-editor__item-title--spacer {
  margin-bottom: calc(1.5rem * 1.5);
}

@media (min-width: 48em) {
  .c-widget-editor__item-title--spacer {
    margin-bottom: 0;
  }
}

body:not(#visual-portal-wrapper) .u-visible--lang-en {
  display: none;
}

.hidden,
.hiddenStructure {
  visibility: hidden;
}

.u-visibility--hidden,
.hiddenStructure {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: #fff;
  background-color: #000;
}

.u-visibility--hidden a,
.hiddenStructure a {
  color: #fff;
  background-color: #000;
}

.u-backdrop {
  background-image: none !important;
  -o-animation: fadeIn .5s ease-in-out;
  animation: fadeIn .5s ease-in-out;
}

.u-backdrop:before {
  content: "";
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
}

.u-backdrop--inverse::before {
  background: rgba(255, 255, 255, 0.5);
}

.u-backdrop--solid::before {
  background: white;
}

.u-device--ios * {
  cursor: pointer;
}

.u-display--block {
  display: block;
}

.u-display--none {
  display: none;
}

@media (min-width: 36em) {
  .u-display-sm--none {
    display: none !important;
  }
  .u-display-sm--block {
    display: block !important;
  }
}

@media (min-width: 48em) {
  .u-display-md--none {
    display: none !important;
  }
  .u-display-md--block {
    display: block !important;
  }
}

@media (min-width: 62em) {
  .u-display-lg--none {
    display: none !important;
  }
  .u-display-lg--block {
    display: block !important;
  }
}

@media (min-width: 75em) {
  .u-display-xl--none {
    display: none !important;
  }
  .u-display-xl--block {
    display: block !important;
  }
}

@media (min-width: 62499.9375em) {
  .u-display-navbar--none {
    display: none !important;
  }
  .u-display-navbar--block {
    display: block !important;
  }
}

.u-display--full-width {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

.u-color--muted {
  color: #6c757d;
}

.u-text--word-wrap {
  word-wrap: break-word;
}

.u-text--sm {
  font-size: 1rem;
}

.u-text--muted {
  color: #5a6570;
}

[id="personal-bar-container"] {
  z-index: 105;
}
