$c-page-header-margin-bottom:                   1.5rem;
$c-page-header-font-size-base:                  1.25rem;
$c-page-header-font-size-lg:                    2rem;
$c-page-header-font-weight:                     400;
$c-page-header-line-height:                     1.5;

.c-page-header {
    margin: 0 auto $c-page-header-margin-bottom;
    @include make-container();
}

.c-page-header__title {
    @include margin-bottom($c-page-header-margin-bottom);
}

.c-page-header__headline {
    padding: .125rem 0;
    color: $app-color-primary;
    line-height: 1.25;
    &--primary {
        color: $app-color-primary;
    }
}

.c-page-header__abstract {
    @include font-size($c-page-header-font-size-lg);
    font-weight: $c-page-header-font-weight;
    line-height: $c-page-header-line-height;
}

.c-page-header__meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include padding-bottom($app-spacer-base);
    @include font-size($c-page-header-font-size-base);

    > * {
        @include padding-right($app-spacer-sm);
        &:first-child {
            flex: 0 0 100%;
        }
    }

    color: $app-color-secondary;
}