$c-language-selection-spacer-x:            1.5rem;
$c-language-selection-spacer-y:            1rem;


.c-language-selection {

}

.c-language-selection__item {
  display: flex;
  justify-content: space-between;
  padding: $app-spacer-sm;
  border: .125rem solid $app-color-gray-lighter;
  margin-bottom: 1rem;
  text-decoration: none;
  &--secondary {
    margin-left: 5rem;
  }
  &:hover {
    border-color: $app-color-text;
  }
}

.c-language-selection__item-aside {
  flex: 0 0 4rem;
  max-width: 4rem;
  height: 3rem;
  border-right: 1px solid $app-color-gray-lighter;
  color: $app-color-gray-lighter;
}

.c-language-selection__item-actions {
  flex: 0 0 8rem;
  max-width: 16rem;
  text-align: right;
  @include mq($from: navbar) {
    flex: 0 0 16rem;
  }
}

.c-language-selection__item-main {
  flex: 1 0 auto;
  max-width: 80%;
  padding-left: $app-spacer-sm;
  padding-right: $app-spacer-sm;
  &--centered {
    display: flex;
    align-items: center;
  }
}


.c-language-selection__item-title {
  margin: 0;
  line-height: 1;
}
