//
// Headings
//
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    @include margin-bottom($app-headings-margin-bottom);
    font-family: $app-headings-font-family;
    font-weight: $app-headings-font-weight;
    line-height: $app-headings-line-height;
    color: $app-headings-color;
    text-transform: $app-headings-text-transform;
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
    font-family: $app-headings-font-family-secondary;
}


h1, .h1 { @include font-size($app-font-size-h1); }
h2, .h2 { @include font-size($app-font-size-h2); }
h3, .h3 { @include font-size($app-font-size-h3); }
h4, .h4 { @include font-size($app-font-size-h4); }
h5, .h5 { @include font-size($app-font-size-h5); }
h6, .h6 { @include font-size($app-font-size-h6); }

