// Optional plone settings and adjustments
// ---------------------------------------

.u-visible--lang-en {
    @include when-inside('body:not(#visual-portal-wrapper)') {
        // static theme usecase
        display: none;
    }
}

// Combat plone hidden element class via mixin
.hidden,
.hiddenStructure {
    visibility: hidden;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/
.u-visibility--hidden,
.hiddenStructure {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    color: #fff;
    background-color: #000;
}

.u-visibility--hidden a,
.hiddenStructure a {
    color: #fff;
    background-color: #000;
}
