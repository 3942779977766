/* lora-regular - latin */
@font-face {
	font-family: 'Lora';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('../assets/fonts/lora-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../assets/fonts/lora-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lora-700 - latin */
@font-face {
	font-family: 'Lora';
	font-style: normal;
	font-weight: 700;
	src: local(''),
	url('../assets/fonts/lora-v17-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../assets/fonts/lora-v17-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lora-italic - latin */
@font-face {
	font-family: 'Lora';
	font-style: italic;
	font-weight: 400;
	src: local(''),
	url('../assets/fonts/lora-v17-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../assets/fonts/lora-v17-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	src: local(''),
	url('../assets/fonts/montserrat-v15-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../assets/fonts/montserrat-v15-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('../assets/fonts/montserrat-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../assets/fonts/montserrat-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 900;
	src: local(''),
	url('../assets/fonts/montserrat-v15-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../assets/fonts/montserrat-v15-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}