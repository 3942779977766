$c-nav-bar-background:                  $app-color-primary;


// Main site nav bar
.c-nav-bar {
    position: fixed;
    top: 0;
    z-index: 1001;
    width: 90vw;
    height: 100vh;
    overflow-y: scroll;
    @if $nav-bar-overlay-ltr == true {
        left: $nav-bar-position-default;
    } @else {
        right: $nav-bar-position-default;
    }
    border-top: 0px solid $gray-900;
    background-color: $c-nav-bar-background;
    box-shadow: 0 1rem 2rem rgba(0,0,0,0.25), 0 .65rem .65rem rgba(0,0,0,0.22);
    &--overlay {
        pointer-events: all;
        right: 0;
        transition:right .5s linear;
        @include mq($from: md) {
            width: 50vw;
        }
    }
    @include mq($from: navbar) {
        position: relative;
        display: block;
        right: 0;
        width: 100%;
        min-height: 3rem;
        height: auto;
        flex: 1 0 100%;
        background: $gray-600;
        box-shadow: none;
    }
}

.c-nav-bar--hidden {
    // height: 0;
    transition: right .5s linear;
}

.c-nav-bar__header {
    display: flex;
    min-height: 8rem;
    padding-left: 1rem;
    padding-right: 0;
    background-color: $c-nav-bar-background;
    color: $white;
    border-bottom: 2px solid $app-color-white;
    @include mq($from: md) {
        padding-left: 2rem;
        padding-right: 1rem;
    }
    @include mq($from: navbar) {
        display: none;
    }
}

.c-nav-bar__title {
    width: 100%;
    // padding: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-family: $app-font-family-strong;
    text-transform: uppercase;
    font-size: 1.25rem;
    //display: none;
    color: $c-nav-bar-background;
}

.c-nav-bar__toggle {
    width: 4rem;
    // align-self: flex-end;
    line-height: 1;
    background: transparent; // remove default button style
    border: 1px solid transparent; // remove default button style
    // font-size: 3rem;
    color: $c-nav-bar-background;
}

.app-nav__toggle-icon {
    > polygon {
        fill: $c-nav-bar-background;
    }
}

.c-nav-bar__main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
