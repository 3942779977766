$o-burger-display:                  block;
$o-burger-position:                 fixed;
$o-burger-flex-direction:           row;

$o-burger-margin-top:               0;
$o-burger-padding-y:                .25rem;

$o-burger-background-color:         transparent;    // remove default button styles
$o-burger-border-width:             1px;            // remove default button style
$o-burger-border-style:             solid;          // remove default button style
$o-burger-border-color:             transparent;    // remove default button style

$o-burger-font:                     $app-font-family-light;

// Burger Text
$o-burger-text-color:               $app-color-text;
$o-burger-text-color-active:        $app-color-white;
$o-burger-text-font-size:           1.45rem;
$o-burger-text-transform:           uppercase;

// Burger icon
$o-burger-icon-color:               $o-burger-text-color;
$o-burger-icon-font-size:           1.5rem;
$o-burger-icon-font-small:          1rem;

$o-burger-layer-width:              40px;
$o-burger-layer-height:             4px;
$o-burger-layer-spacing:            6px;
$o-burger-layer-color:              $app-color-text;
$o-burger-layer-color-active:       $app-color-white;
$o-burger-layer-border-radius:      4px;
