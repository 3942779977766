$c-slider-color:                  $app-color-white;
$c-slider-padding:                $app-spacer-base;

.c-slider {
  display: block;
  width: 100vw;
  //width: 100vw;
  //margin-left: 50%;
  //transform: translateX(-50%);
  margin-left: -($app-spacer-base);
  margin-right: -($app-spacer-base);
  @include mq($from: lg) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.c-slider__main {
  width: 100%;
  //touch-action: none;
  //-webkit-user-select: none;
  //-webkit-user-drag: none;
}

.c-slider__items {
  display: block;
  position: relative;
  width: 100%;
}

.c-slide {
  display: block;
  position: relative;
  width: 100%;
}

.c-slide__main {
  position: relative;
  padding: 2rem 2rem .5rem 2rem;
  color: $app-color-text;
  width: 100%;
  background: #fff;
  @include mq($from: md) {
    position: absolute;
    bottom: 0;
    left: 0;
    color: $c-slider-color;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.45));
    padding: 3rem 5rem;
  }
}

.c-slide__content {
  a {
    color: $app-color-primary;
    &:hover {
      color: $app-color-primary;
    }
    &:visited {
      color: $app-color-primary;
    }
  }
  @include mq($from: md) {
    padding-bottom: 0;
      a {
      color: #fff;
      &:hover {
      color: #fff;
      }
      &:visited {
      color: #fff;
      }
    }
  }
}
.c-slider__items {
  margin-bottom: 0;
}

.c-slide__figure {
  margin-bottom: 0;
}

.o-figure__card {
    @include when-inside('.c-slide__figure') {
        &::before {
            @include mq($until: sm) {
                padding-bottom: 100%;
            }
        }
    }
}

/* ---- flickity overrides ---- */

.flickity-prev-next-button {
  background: transparent;
  @include mq($from: lg) {
    width: 100px;
    height: 100px;
  }
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: -200%;
  width: 60%;
  height: 60%;
  @include mq($from: md) {
    top: 20%;
  }
}

.flickity-prev-next-button .arrow {
  fill: #fff;
}

.flickity-prev-next-button:hover {
    background: none;
}

.flickity-page-dots {
  display: block;
  text-align: center;
  position: absolute;
  top: 55%;
  padding-top: 0;
  max-height: 30px;
  @include mq($from: md) {
    border: 0;
    top: 85%;
    bottom: 2rem;
    padding-top: 1rem;
  }
  @include mq($from: xl) {
    top: 90%;
  }
  .dot {
    background-color: #fff;
    @include mq($from: md) {
      background-color: #fff;
      }
    }
}


