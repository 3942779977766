.c-teaser {
    width: 100%;
}

.c-teaser__header {
    margin-bottom: $app-spacer-base;
    color: #000;
}

.c-teaser__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.c-teaser__footer {
    text-align: right;
    font-family: $app-font-family-strong;
    padding-bottom: 1rem;
    @include mq($from: lg) {
        padding-bottom: 0;
    }
}

.c-teaser__link {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
