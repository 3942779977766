//
// Cards displaying thumbnails and data collections
// ------------------------------------------------------
$c-card-display-default:                flex;
$c-card-width-default:                  100%;
$c-card-font-family-base:               $app-font-family-light;
$c-card-font-family-highlight:          $c-card-font-family-base;

$c-card-cap-bg:                         $app-color-white;

$c-card-color:                          $app-color-text;
$c-card-color-link:                     $app-color-primary;
$c-card-color-link-hover:               darken($c-card-color, 10%);

$c-card-spacer:                         $app-spacer-sm;


.c-card {
    display: $c-card-display-default;
    @if $c-card-display-default == 'flex' {
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    } @else {
        height: auto;
    }
    width: $c-card-width-default;
    padding-bottom: $app-spacer-base;
    @include when-inside('.c-list__item') {
        padding-bottom: 0;
    }
}

.c-card--spacer {
    padding: $c-card-spacer;
}

.c-card--page-section {
    display: block;
    height: auto;
    @include mq($from: lg) {
        margin-left: $app-spacer-base;
    }
}


.c-card--nav {
    justify-content: flex-start;
}

//== Card layout
//
//## Reflow card contents based on viewport size
.c-card__header {
    font-family: $c-card-font-family-highlight;
    font-size: $app-font-size-h5;
    font-weight: 300;
    padding: $c-card-spacer;
    background: $c-card-cap-bg;
    @include when-inside('.c-card--default') {
        flex: 1 0 auto;
        height: 8rem;
        text-align: center;
    }
    @include when-inside('.c-card--page-section') {
        background: $app-color-gray-light;
        font-size: $app-font-size-h6;
        line-height: 1.25;
    }
    @include when-inside('.c-card--selector') {
        background: $app-color-gray-light;
    }
}

//** Card content
.c-card__main {
    font-family: $app-font-family-base;
    background-color: $app-color-white;
    // display: flex;
    height: 100%;
    // flex-direction: column;
    @include when-inside('.c-card--page-section') {
        display: block;
        font-size: $app-font-size-sm;
    }
    @include when-inside('.c-card--nav') {
        @include mq($from: lg) {
            //overflow: visible;
            max-height: 1500px;
            display: block;
        }
    }
}

//** Card footer
.c-card__footer {
    margin-top: auto;
    font-family: $c-card-font-family-base;
    color: $app-color-white;
    background: $app-color-secondary;
    @include when-inside('.c-card--news') {
        text-align: left;
    }
    @include when-inside('.c-card--default') {
        text-align: center;
        padding: $app-spacer-sm;
    }
    font-size: $font-size-small;
    &--centered {
        text-align: center;
    }
    &--action {
        display: inline-block;
        margin-top: .7rem;
        padding: 0;
        width: 1.1rem;
        height: 1.1rem;
        border: 1px solid $app-color-text;
        line-height: 1;
        border-radius: 50%;
    }

    &--link {
        display: inline-block;
        padding: 0 0 0 .5rem;
        margin-top: .5rem;
        color: $app-color-white !important;
        //width: 2rem;
        //height: 2rem;
    }
}

// Card element styles
.c-card__link {
    display: block;
    width: 100%;
    color: $c-card-color-link;
    text-decoration: none;
    &:hover {
        color: $c-card-color-link-hover;
        text-decoration: none;
    }
    &--header {
        text-align: center;
    }
    &--main {
        color: $app-color-text;
        text-align: center;
    }
}

.c-card__date {
    font-weight: 100;
    @include font-size($app-font-size-base);
    color: $c-card-color-link;
}


// TODO: evaluate if these could be moved to generalized rules
// Card contents
.c-card__title {
    font-family: $c-card-font-family-base;
    color: $c-card-color-link;
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    text-transform: uppercase;
}

.c-card__title-text {
    align-self: center;
}

.c-card__section {
    padding: $app-spacer-sm;
    &--centered {
        text-align: center;
    }
}

.c-card__detail {
    font-family: $c-card-font-family-base;
}